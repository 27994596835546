import React from 'react'

function PageLength(props) {
    const { pageLength, setPageLength } = props
    const pageLengthOptions = [10, 25, 50, 100, 500, 2000]

    return (
        <div className='ml-1'>
            Show 
            <select data-testid="pagelength-dropdown" className="page-length mx-2" onChange={e => setPageLength(e.target.value) }>
                {
                    pageLengthOptions.map((opt, idx) => (
                        <option key={ idx } data-testid={ `option-${idx}` } value={ opt } selected={ pageLength === opt }>{ opt }</option>
                    ))
                }
            </select>
            entries
        </div>
    )
}

export default PageLength
