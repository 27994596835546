import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { Radios, InputBox } from 'components';
import { pathFor } from 'constants/apiPaths';
import { validateEmail } from 'utils/validations';
import Request from 'services/Request';
import { useParams } from 'react-router-dom';
import { capitalise } from 'utils/common';
import useSnackbar from 'services/useSnackbar';


function EditUser(props) {
    const setMessage = useSnackbar()
    const { currentOrg } = props
    const history = useHistory()
    const { id } = useParams()
    const [ readOnly, setReadOnly ] = useState(false)
    const [ name, setName ] = useState("")
    const [ email, setEmail ] = useState("")
    const [ company_name, setCompanyName ] = useState("")
    const [ active, setActive ] = useState("")
    const [ junk, setJunk ] = useState("")
    const [ emailError, setEmailError ] = useState(null)
    const [ nameError, setNameError ] = useState(null)
    const [ companyNameError, setCompanyNameError] = useState(null)
    const [ changeStates, setChangeStates ] = useState({})
    const userStatuses = ["Active", "Inactive"]
    const { organisation_id } = currentOrg

    const handleChange = (key, value) => {
        const tempState = { ...changeStates }
        key === "status" ? setActive(value) : key === "name" ? setName(value) : key === "email" ? setEmail(value) : setCompanyName(value)
        tempState[key]["updated"] = value
        setChangeStates(tempState)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (readOnly && junk) return setMessage({error: "The email cannot be changed."})
        if (!validateEmail(email)) return setEmailError("Invalid email.")
        if (name.length < 3 || name.length > 40) return setNameError("Length must be between 3 and 40")
        if (company_name.length < 3 || company_name.length > 20) return setCompanyNameError("Length must be between 3 and 20")
        
        const to_be_logged = Object.fromEntries(Object.entries(changeStates).filter(([k, v]) => v.updated && v.updated !== v.previous))
        try {
            const { statusCode, jsonResponse } = await Request(`${pathFor.EditMember}/${id}`, { name, email, company_name, status: active, organisation_id, to_be_logged })
            setMessage(jsonResponse)
            if (statusCode === 200) history.push("/dashboard/user-management")
        } catch {
            setMessage({error: "Server error. Try again after sometime."})
        }
    }

    // eslint-disable-next-line
    useEffect(async () => {
        const tempState = { ...changeStates }
        try {
            const { statusCode, jsonResponse } = await Request(`/member/${id}`)
            if (statusCode === 200) {
                const { member_email, member_name, member_company_name, status } = jsonResponse
                setName(member_name || "")
                tempState["name"] = { previous: member_name }
                setEmail(member_email || "")
                tempState["email"] = { previous: member_email }
                setReadOnly(status !== "invited")
                tempState["company_name"] = { previous: member_company_name }
                setCompanyName(member_company_name || "")
                setActive(status)
                tempState["status"] = { previous: status }
            } else setMessage(jsonResponse)
        } catch(err) {
            setMessage({error: err.message + " member details."})
        }
        setChangeStates(tempState)
        // eslint-disable-next-line
    }, [])

    return (
        <div className="mx-3 mt-3">
            <form className="col-xl-4 col-lg-5 col-md-6 col-sm-7 col-8 py-4 px-3" onSubmit={ handleSubmit }>
                <p className="label-text mb-1">EDIT USER</p>
                <div className=" my-4">
                    <InputBox type="text" id="client-name" 
                        placeholder="Name" 
                        errorText={ nameError }
                        onChange={e => { setNameError(null); handleChange("name", e.target.value); }}
                        value={ name } 
                    />
                </div>
                <div className="my-4">
                    <InputBox type="email" id="client-email" 
                        placeholder="Email" 
                        readOnly={ readOnly }
                        errorText={ emailError }
                        onChange={e => { setEmailError(null); readOnly ? setJunk(e.target.value) : handleChange("email", e.target.value); }} 
                        value={ email }
                    />
                </div>
                <div className='my-4'>
                    <InputBox type="text" id="client-company-name"
                        placeholder="Company name"
                        errorText={ companyNameError } 
                        onChange={e => { setCompanyNameError(null); handleChange("company_name", e.target.value); }}
                        value={ company_name }
                    />
                </div>
                {
                    readOnly && (
                        <div className="m-3">
                            <Radios items={ userStatuses } selected={ capitalise(active) } setSelected={(value) => handleChange("status", value.toLowerCase())} childClassName="col-6" />
                        </div>
                    )
                }
                <div className="d-flex justify-content-end mt-5">
                    <button type="button" className="btn bg-white px-3 py-2 btn-cancel text--primary" onClick={() => history.push("/dashboard/user-management")}>Cancel</button>
                    <button type="submit" className="btn bg--primary text--white py-2 px-3 ml-3" disabled={ !(name.length && email.length)}>Make changes</button>
                </div>
            </form>
        </div>
    )
}

export default EditUser
