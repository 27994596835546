import React from 'react'

function InfoDisplay(props) {
    const { start, end, total } = props.info

    return (
        <span data-testid="datatable-info" className="mx-1">
            {
                total ? <>
                    Showing { total !== 1 && `${start + 1} to ${ end } of` } { total } entr{ total === 1 ? "y" : "ies" }
                </> : <></>
            }
        </span>
    )
}

export default InfoDisplay
