import React from 'react'


function Radios(props) {
    const { items, selected, setSelected, className, childClassName, disabledItems } = props

    return (
        <div className={ `pointer ${className || "row"}` }>
            {
                items.length ? items.map(item => {
                    let option, disabled;
                    if (typeof item === "object") {
                        option = item.text
                        disabled = item.disabled
                    } else {
                        option = item
                        disabled = disabledItems?.includes(item)
                    }

                    return (
                        <div data-testid={ `radio-${option}` } className={ `${childClassName} d-flex align-items-center` } onClick={() => !disabled && setSelected(item)}>
                            <span className={ `radio-button ${ selected === item ? "filled" : "hollow"} mr-2 d-flex align-items-center justify-content-center` }>
                                <span className={ disabled ? "bg--gray p-2 rounded-circle" : selected === item ? "radio-fill" : "pointer" }></span>
                            </span>
                            <span className={ `radio-text ${ disabled && "text--grey font-weight-bold" }` }>{ option }</span>
                        </div>
                    )
                }) : (
                    <span>No items available.</span>
                )
            }            
        </div>
    )
}

export default Radios
