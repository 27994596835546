import React from 'react'


function Btn(props) {
    const { variant, className } = props
    const bgVariant = variant || "-primary"
    const textVariant = bgVariant === "-grey" ? "-primary" : "white"

    return (
        <button { ...props }
            className= { `${ className } border-0 rounded bg-${ bgVariant } text-${ textVariant } py-2 px-3 font-weight-bold` } 
        >
            { props.children }
        </button>
    )
}

export default Btn