import React, { useState } from 'react'
import LogoSection from './LogoSection'
import { useHistory, useRouteMatch } from 'react-router-dom';
import ProfileIcon from 'assets/icons/misc/ProfileIcon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faCaretRight, faEye } from '@fortawesome/free-solid-svg-icons';
import { capitalise } from 'utils/common';


function Navbar(props) {
    const { title, username, handleCollapse, dashboardType, setDashboardType, internal } = props
    const [ hovered, setHovered ] = useState("")
    const history = useHistory()
    const match = useRouteMatch()

    const crumbs = (() => {
        const crumbsArray = []
        const pathname = history.location.pathname.replace(match.path, "").trim();
        const splittedPathname = pathname.split("/").filter(i => i.length)
        
        splittedPathname.forEach((item, idx) => {
            const text = item.split("-").map(i => capitalise(i)).join(" ")
            
            if (splittedPathname.length <= 1 || !text?.length) return
            if (idx) crumbsArray.push(title === "User Management" ? <span className='mx-1 size-12'>/</span> : <FontAwesomeIcon icon={ faCaretRight } size="sm" className='mx-1' />)
            crumbsArray.push(text)
        })

        if (!["Create Dataset", "User Management"].includes(crumbsArray[0])) return
        return crumbsArray
    })()

    let menuOptions = [
        {
            text: "Logout",
            clickAction: () => history.push("/accounts/login"),
            icon: faArrowRightFromBracket
        }
    ]

    if (internal) {
        let inverseType = dashboardType === "publisher" ? "advertiser" : "publisher"
        menuOptions = [{
            text: `${ capitalise(inverseType) } View`,
            clickAction: () => { 
                sessionStorage.setItem("currentView", inverseType) 
                setDashboardType(inverseType)
            },
            icon: faEye
        }, ...menuOptions]
    }


    return (
        <div className='d-flex bg--secondary shadow'>
            <LogoSection handleCollapse={ handleCollapse } />
            <div className='flex-grow-1 px-4 d-flex justify-content-between align-items-center'>
                <div className='mx-4 px-4'>
                    <h5 className='text--primary font-weight-bold m-0 p-0'>{ title }</h5>
                    {
                        crumbs?.length ? (
                            <div className='text--secondary d-flex align-items-center'>
                                {
                                    crumbs.map(crumb => (
                                        <div className='d-flex align-items-center'>
                                            <span className='size-12'>{ crumb }</span>
                                        </div>
                                    ))
                                }
                            </div>
                        ) : <></>
                    }
                </div>
                <div className='profile-container py-3 d-flex align-items-center'>
                    <p className='text--primary font-weight-bold mr-3 mb-0'>{ username || "User" }</p>
                    <div className='user-icon pointer'>
                        <img src={ ProfileIcon } />
                        <div className="user-menu shadow rounded-lg p-3">
                            {
                                menuOptions.map(({ text, clickAction, icon }) => (
                                    <p className={ `m-0 px-4 py-2 rounded pointer ${ hovered === text ? "bg--grey text--primary" : "text-muted" }` } 
                                        onMouseEnter={() => setHovered(text)}
                                        onMouseLeave={() => setHovered("")}
                                        onClick={ clickAction }
                                    >
                                        <FontAwesomeIcon icon={ icon } size="lg" className="mr-3 m-item" />
                                        { text }
                                    </p>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar