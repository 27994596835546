const userStats = [
	{
		text: "Total",
		key: "total",
		defaultValue: "0"
	},
	{
		text: "Active",
		key: "active",
		defaultValue: "0",
	},
	{
		text: "Inactive",
		key: "inactive",
		defaultValue: "0",
	},
	{
		text: "Invited",
		key: "invited",
		defaultValue: "0",
	}
]

const publisherDataSetActivities = [
	{
		text: "Total downloaded",
		key: "downloaded",
		defaultValue: "0"
	}
]


const advertiserDatasetActivities = [
	{
		text: "Total used",
		key: "total_used",
		defaultValue: "0"
	},
	{
		text: "Downloaded",
		key: "downloaded",
		defaultValue: "0"
	},
	{
		text: "Pushed",
		key: "pushed",
		defaultValue: "0"
	},
	{
		text: "Push failed",
		key: "push_failed",
		defaultValue: "0"
	}
]

const datasetActivities = {
	"publisher": publisherDataSetActivities,
	"advertiser": advertiserDatasetActivities
}


const banners = dashboardType => [
	{
		bannerKey: "UsersStats",
		title: "USERS",
		metrics: userStats
	}, {
		bannerKey: "DatasetActivities",
		title: "URLS",
		metrics: datasetActivities[dashboardType],
		datewise: true
	}, 
]

export { banners };