import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { Dropdown } from 'react-bootstrap'


function DropDown(props) {
    const { options, setSelected, defaultValue, className, childClassName, dropUp } = props
    const [ showMenu, setShowMenu ] = useState(false)
    const [ displayText, setDisplayText ] = useState("")
   

    return (
        <div className={ className } onClick={() => setShowMenu(!showMenu)}>
            <button className={ `btn btn-white drop-down d-flex justify-content-between mt-1 pt-2 ${ childClassName }` }>
                <span className="text-truncate">{ displayText.trim().replace("-", "") || defaultValue }</span>
                <FontAwesomeIcon icon={ dropUp ? faCaretUp : faCaretDown } size="lg" />
            </button>
            <div className={ `d-${ showMenu ? "block" : "none"} drop-menu drop-${ dropUp ? "up" : "down" }-menu py-1 ${ childClassName }` }>
                {
                    options.map(item => {
                        let displayValue, value;
                        displayValue = value = item;

                        if (item instanceof Object) {
                            value = Object.keys(item)[0]
                            displayValue = Object.values(item)[0]
                        } 

                        if (displayText === displayValue) {
                            displayValue = "-"
                            value = ""
                        }
                        
                        return (
                            <div className="drop-down-item" data-testid={ `drop-down-item-${displayValue} ` } onClick={() => {
                                setDisplayText(displayValue);
                                setSelected(value); 
                            }}>{ displayValue }</div>
                        )
                    })
                }
            </div>
        </div>
    )
}


const getOptionValue = option => typeof option === "object" ? option.displayText : option

function AltDropDownMenuOption(props) {
    const { option, multi, selected, setSelected } = props
    const value = getOptionValue(option)

    const handleClick = () => {
        setSelected(
            selected?.includes(value) 
                ? selected.filter(i => i !== value)
                : [ ...( multi ? selected : []), value]
        )
    }

    return (
        <>
            {
                multi ? (
                    <span className="w-100 px-3 py-1 d-flex align-items-center pointer position-relative menu-option" onClick={ handleClick }>
                        <input type="checkbox" className="mr-3" checked={ selected.includes(value) } /> 
                        { value } 
                    </span>
                ) : (
                    <Dropdown.Item className='px-3 text-muted' onClick={ handleClick }><span>{ value }</span></Dropdown.Item>
                )
            }
        
        </>
    )

}


function AltDropDownMenu(props) {
    const inputRef = useRef()
    const { options, filter, selected, selectedFirst, toggleState } = props
    const [ menuOptions, setMenuOptions ] = useState([])
    const [ filterText, setFilterText ] = useState("")
    
    useEffect(() => {
        let tempOptions = [ ...options ]
        if (filterText?.length) {
            tempOptions = options?.filter(o => (
                getOptionValue(o).toLowerCase().includes(filterText.toLowerCase())
            )) || []
        } else {
            if (selectedFirst) {
                const selectedOptions = options.filter(item => selected.includes(getOptionValue(item)))
                const remainingOptions = options.filter(item => !selected.includes(getOptionValue(item)))
        
                tempOptions = [ ...selectedOptions, ...remainingOptions ]
            }
        }
        setMenuOptions(tempOptions)
        toggleState && inputRef.current?.focus()
    }, [ filterText, toggleState ])
    

    return (
        <Dropdown.Menu className={ `${options ? "w-100 shadow-lg border-0 p-2" : "d-none"}` }>
            {
                filter ? (
                    <div className="p-2">
                        <input ref={ inputRef } 
                            className="mb-2 w-100 border rounded py-1 px-2" 
                            placeholder="Type to filter..." 
                            onChange={e => setFilterText(e.target.value.trim())} 
                        /> 
                        <Dropdown.Divider />
                    </div>
                ) : <></>
            }
            <div className="scrollable menu">
                {
                    menuOptions.map(option => (
                        <AltDropDownMenuOption option={ option } { ...props } />
                    ))
                }
            </div>
        </Dropdown.Menu>
    )
}


function AltDropDown(props) {
    const { className, title, label, placeholder, selected } = props
    const [ miniLabel, setMiniLabel ] = useState(null)
    const [ toggleState, setToggleState ] = useState(false)
    
    useEffect(() => {
        if (selected) {
            setMiniLabel(selected?.length ? label : null)
        }
    }, [ selected ])


    return (
        <Dropdown onToggle={ setToggleState }>
            <Dropdown.Toggle variant="white" id="dropdown-basic" className={ className + " position-relative" }>
                {
                    miniLabel && <span className='dropdown-mini-label rounded-pill'>{ miniLabel }</span>
                }
                <div className="pr-4 d-inline">
                    { 
                        title || (
                            !selected?.length 
                                ? <span className='text--secondary'>{ placeholder }</span>
                                : selected?.length <= 2 
                                    ? selected.slice(0, 2).join(", ")
                                    : "Multiple selected"
                            )
                        }
                </div>
            </Dropdown.Toggle>
            
            <AltDropDownMenu { ...props } toggleState={ toggleState } />
        </Dropdown>
    )
}


export { AltDropDown }
export default DropDown