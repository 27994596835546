import { Btn, Modal } from 'components'
import React, { useEffect, useState } from 'react'
import useSnackbar from 'services/useSnackbar'
import { validateDomain } from 'utils/validations'


function AddModal(props) {
    const setMessage = useSnackbar()
    const { callbackFn, setAddModal, addModal, domains } = props
    const [ isModalOpen, setIsModalOpen ] = useState(false)
    const [ newDomain, setNewDomain ] = useState("")

    const closeModal = () => {
        setIsModalOpen(false)
        setAddModal(false)
    }

    useEffect(() => {
        setIsModalOpen(addModal)
    }, [ addModal ]) 

    const handleSubmit = event => {
        event.preventDefault();

        if (!validateDomain(newDomain)) return setMessage({
            error: newDomain.includes("://") 
                ? `Kindly drop '${ newDomain.split("://")[0] }://' from the input.`
                : "Invalid domain."
        })

        console.log(validateDomain(newDomain))
        callbackFn([ ...domains, newDomain ])
        closeModal()
    }

    return (
        <Modal handleClose={ closeModal } isOpen={ isModalOpen } className="w-50">
            <h5 className='text-muted font-weight-bold'>Add Domain</h5>
            <form className='d-flex alsign-items-between mt-3' onSubmit={ handleSubmit }>
                <input className='border px-3 rounded mr-3 flex-grow-1' placeholder='Enter domain' onChange={e => setNewDomain(e.target.value.trim())} />
                <Btn type="submit" className="cw-1">Save</Btn>
            </form>
        </Modal>
    )
}

export default AddModal