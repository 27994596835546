import React, { useRef } from 'react'
import useSnackbar from 'services/useSnackbar'


function FileChooser(props) {
    const setMessage = useSnackbar()
    const { setUploadedFile, validateFile, accept } = props
    const hiddenFileRef = useRef()
  
    const onChange = event => {
        setUploadedFile(null)
        const file = event.target.files[0]
        const fileValidationError = validateFile && validateFile(file)

        if (!fileValidationError) {
            setUploadedFile(event.target.files[0])
        } else {
            setMessage(fileValidationError)
        }
    }

    return (
        <>
            <div className='file-container rounded d-flex justify-content-center h-100 align-items-center text-muted pointer'
                    onClick={() => hiddenFileRef.current.click()}
                >
                    Choose file...
            </div>
            <input type="file"
                ref={ hiddenFileRef }
                onChange={ onChange }
                className="d-none"
                accept={ accept }
            />
        </> 
    )
}

export default FileChooser