import React from 'react'
import logo from 'assets/images/logo/Logo.svg'
import logoText from 'assets/images/logo/LogoText.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'


function LogoSection(props) {
    const { handleCollapse } = props

    return (
        <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={ faBars } size="2x" className='ml-4 pl-1 pointer' onClick={ handleCollapse } />
            <a href="/">
                <img src={ logo } alt="logo" className="ml-4" height="44" width="44"/>
                <img src={ logoText } alt="logo" className="ml-2 pl-1" height="13" />
            </a>
        </div>
    )
}

export default LogoSection