import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { DataTable } from 'components'
import { pathFor } from 'constants/apiPaths'
import { usersTableHead } from 'constants/tableConstants'
import Request from 'services/Request'
import { ReactComponent as SearchIcon } from 'assets/icons/misc/SearchIcon-active.svg'
import useSnackbar from 'services/useSnackbar'


function UsersManagement() {
    const setMessage = useSnackbar()
    const history = useHistory()
    const [ tableBody, setTableBody ] = useState(null)
    const [ filterBy, setFilterBy ] = useState(null)
    const searchFields = ["member_name", "member_email"]

    const filterUsers = event => {
        event.preventDefault()
        setFilterBy({ key: event.target.filterValue.value.trim(), fields: searchFields })
    }
    
    // eslint-disable-next-line
    useEffect(async () => {
        try{
            const { statusCode, jsonResponse } = await Request(pathFor.Members)
            if (statusCode === 200) {
                const { data } = jsonResponse
                setTableBody(data)
            } else setMessage(jsonResponse)
        } catch {
            setMessage({error: "Server error. Try again after sometime."})
        }
    }, [])

    return (
        <div className="m-3 p-3">
            <p className="label-text col-7 px-0">CURRENT USERS</p>
            <div className='d-flex w-50 my-4'>
                <form className="search-filter border--light rounded d-flex flex-grow-1 justify-content-end mb-1" onSubmit={ filterUsers }>
                    <input placeholder="Search" name="filterValue" className="border-0 outline-none rounded py-2 pl-3 flex-grow-1"/>
                    <button type="submit" className="border-0 bg-white p-0">
                        <SearchIcon className="my-1 mr-2 ml-1" height={ 30 } width={ 30 }/>
                    </button>
                </form>
                <button type="button" className="btn bg--primary text--white px-3 ml-3 mb-1" onClick={() => history.push(history.location.pathname + "/add-new-user")}>Add new user</button>
            </div>
        
            <DataTable tableData={ { tableHead: usersTableHead, tableBody } } filterBy={ filterBy } noExport={ true } unresponsive />
        </div>
    )
}

export default UsersManagement
