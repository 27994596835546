import React from 'react'
import Check from 'assets/icons/misc/Check.png'


function CheckBoxes(props) {
    const { items, selected, setSelected, childClassName } = props
    
    const handleCheck = value => {
        let temp = [ ...selected ]
        if (temp.includes(value)) temp = temp.filter(t => t !== value)
        else temp.push(value)
        setSelected(temp)
    }

    return (
        <div className="row">
            {
                items.length ? items.map(item => (
                    <div data-testid={ `checkbox-${item}` } className={ childClassName + " d-flex align-items-center pointer" } onClick={() => handleCheck(item)}>
                        <span className="checkbox">
                            {
                                selected.includes(item) && (
                                    <img src={ Check } alt="selected" width="16" className="check-icon"/>
                                )
                            }
                        </span>
                        <span>{ item }</span>
                    </div>
                )) : (
                    <span>No items available.</span>
                )
            }            
        </div>
    )
}

export default CheckBoxes
