import React, { useEffect, useRef, useState } from 'react'
import { Btn, ConfirmationBox, Helper } from 'components'
import useSnackbar from 'services/useSnackbar'
import UploadModal from './UploadModal'
import AddModal from './AddModal'
import { pathFor } from 'constants/apiPaths'
import DomainsTable from './DomainsTable'
import { CSVLink } from 'react-csv'
import { pluralise } from 'utils/common'
import DataTableControllers from 'components/Table/DataTableControllers'
import Request from 'services/Request'


function DomainsSection(props) {
    const setMessage = useSnackbar()
    const { currentOrg, dashboardType } = props
    const { organisation_id, owned } = currentOrg
    
    const [ domains, setDomains ] = useState([])
    const [ selectedDomains, setSelectedDomains ] = useState([])
    const [ uploadModal, setUploadModal ] = useState(false)
    const [ addModal, setAddModal ] = useState(false)
    const [ confirmRemoval, setConfirmRemoval ] = useState(false)
    const [ tableBody, setTableBody ] = useState(domains)
    const csvRef = useRef()
    
    const isAdvertiser = dashboardType === "advertiser"
    
    const handleSelect = domain => {
        selectedDomains.includes(domain) 
            ? setSelectedDomains(selectedDomains.filter(item => item !== domain))
            : setSelectedDomains([ domain, ...selectedDomains ])
    }
    
    const updateDomains = async (domains) => {
        try {
            const { statusCode, jsonResponse } = await Request(pathFor.UpdateStoredDomains, { organisation_id, domains, domains_type: isAdvertiser ? "blacklist" : "whitelist" })
            if (statusCode === 200) {
                setDomains(jsonResponse.data)
                setSelectedDomains([])
            } else if ("error" in jsonResponse) setMessage(jsonResponse)
            else setMessage({error: "Unable to update blacklisted domains. Invalid domain/s found."})
        } catch (error) {
            setMessage({error: "Server error. Try again after sometime."})
        }
    }

    useEffect(async () => {
        try {
            const queryParams = currentOrg?.org_type === "internal" ? `?dashboard=${dashboardType}` : ""
            const { statusCode, jsonResponse } = await Request(`${pathFor.FetchStoredDomains}${queryParams}`, { organisation_id })
            if (statusCode === 200) {
                setDomains(jsonResponse.data)
                setSelectedDomains([])
            } else setMessage(jsonResponse)
        } catch (error) {
            setMessage({error: "Server error. Try again after sometime."})
        }
    }, [ dashboardType ])

    const { title, uploadLimit, filename } = {
        advertiser: {
            title: "BLACKLISTED DOMAINS",
            uploadLimit: 30,
            filename: "Blacklisted_domains"
        }, 
        publisher: {
            title: "DOMAINS",
            uploadLimit: 2000,
            filename: "Whitelisted_domains"
        }
    }[dashboardType]

    const modalProps = { callbackFn: updateDomains, domains, uploadModal, setUploadModal, addModal, setAddModal, uploadLimit }
    const domainsTableProps = { editable: owned, domains: isAdvertiser ? domains : tableBody, handleSelect, selectedDomains, setSelectedDomains, _10rows: !isAdvertiser }

    const btnOptions = [
        {
            text: "Add",
            onClick: () => setAddModal(true),
            hidden: isAdvertiser || !owned
        }, {
            text: "Upload",
            onClick: () => setUploadModal(true),
            hidden: !owned
        }, {
            text: "Download",
            onClick: () => csvRef.current.link.click(),
            disabled: !domains?.length || selectedDomains?.length
        }, {
            text: "Remove",
            onClick: () => setConfirmRemoval(true),
            disabled: !domains?.length || !selectedDomains?.length,
            hidden: !owned
        }
    ]

    const Btns = () => (
        <div className='row'>
            { btnOptions.map(btn => <Btn className="mr-3 cw-1" { ...btn }>{ btn.text }</Btn>) }
        </div>
    )


    return (
        <div className='mt-4'>
            <div className='d-flex justify-content-between align-items-end pt-2'>
                <div className='d-flex align-items-center'>
                    <p className="label-text m-0">{ title }</p>
                    { owned ? <Helper itemKey="domains" variant="gray" arg={ uploadLimit } /> : <></> }
                </div>
                { isAdvertiser && <Btns /> }
            </div>
            {
                isAdvertiser ? (
                    <DomainsTable { ...domainsTableProps } />
                ) : (
                    <DataTableControllers records={ domains } setTableBody={ setTableBody } extensions={ <Btns /> }>
                        <DomainsTable { ...domainsTableProps } />
                    </DataTableControllers>
                )
            }
            { domains?.length ? <CSVLink ref={ csvRef } data={[["domain"], ...domains.map(item => [item])]} filename={ `${ filename }.csv` } target="_blank" /> : <></> }
            <AddModal { ...modalProps } />
            <UploadModal { ...modalProps } />
            {
                confirmRemoval ? (
                    <ConfirmationBox 
                        message={ `Are you sure you want to remove ${ selectedDomains.length === domains.length ? "all" : selectedDomains.length } ${ pluralise("domain", selectedDomains.length) }?`}
                        callbackFn={() => updateDomains(domains.filter(item => !selectedDomains.includes(item)))}
                        clearFn={() => setConfirmRemoval(false)}
                    />
                ) : <></>
            }
        </div>
    )
}

export default DomainsSection