import React from 'react'
import { faDotCircle, faCircleMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function PreviewPaneComponent(props) {
    const { title, size, colSize, itemsList, updateItems } = props

    return (
        <>
            {
                itemsList?.length ? (
                    <div className='h-25'>
                        <span className='label-text px-2 border-bottom d-block'>
                            { title }
                        </span>
                        <div className={ `bg-light p-2 row preview-container pc-${size}` }>
                            {
                                itemsList.map(item => item.length ? (
                                    <span className={ `col-${ colSize || 6 } pt-1 preview-pane-item` }>
                                        {
                                            updateItems ? (
                                                <FontAwesomeIcon 
                                                    icon={ faCircleMinus } 
                                                    className='mr-2 text--primary pointer' 
                                                    onClick={() => updateItems(itemsList.filter(i => i != item))} 
                                                />
                                            ) : (
                                                <FontAwesomeIcon icon={ faDotCircle } className='mr-2 text--primary' />
                                            )
                                        }
                                        { item }
                                    </span>
                                ) : <></>)
                            }
                        </div>
                    </div>
                ) : <></>
            }
        </>
    )
}

export default PreviewPaneComponent