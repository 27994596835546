import React, { useState } from 'react'
import { AltDropDown, InputBox, Modal } from 'components'
import PublishToDV360 from '../misc/PublishToDV360'


function PublishModal(props) {
    const { payload, setModalMode, setNinaLoader } = props
    const [ isModalOpen, setIsModalOpen ] = useState(true)
    const [ target_size, setTargetSize ] = useState(null)
    const [ expectedURLsError, setExpectedURLsError ] = useState(null)
    const [ dsp, setDsp ] = useState(["DV360"])

    const closeModal = () => {
        setModalMode(null)
        setIsModalOpen(false)
    }
     
    const handleTargetSize = (value) => {
        const re = /^[0-9]+$/
        if (!re.test(value)) {
            setTargetSize(null)
            return setExpectedURLsError("Not a valid number.")
        }

        value = parseInt(value)    
        setTargetSize(value)
    }

    const handleExpectedURLs = event => {
        setExpectedURLsError(null)
        const value = event.target.value
        if (!value?.length) return setTargetSize(null)
        handleTargetSize(value)
    }

    return (
        <Modal handleClose={ closeModal } isOpen={ isModalOpen } className="h-90">
            <div className='p-2 d-flex flex-column'>
                <h5 className='font-weight-bold mb-4 text--primary'>Publish options</h5>
                <div className='d-flex align-items-center mt-2'>
                    <div className='w-50 mr-5'>
                        <AltDropDown 
                            className="border w-100 py-2 d-flex w-100 justify-content-between align-items-center"
                            label="DSP"
                            placeholder="Select DSP"
                            options={[ "DV360" ]}
                            setSelected={ setDsp }
                            selected={ dsp }
                        />
                    </div>
                    <div className='w-50 ml-5'>
                        <InputBox
                            className='px-3 py-2' 
                            onChange={() => setExpectedURLsError(null)}
                            placeholder="Expected URLs *"
                            onBlur={ handleExpectedURLs }
                            errorText={ expectedURLsError }
                            setErrorText={ setExpectedURLsError }
                        />
                    </div>
                </div>
                <div className='h-100'>
                    {
                        dsp[0] === "DV360" ? (
                            <PublishToDV360 query={ payload } target_size={ target_size} callbackFn={ closeModal } setNinaLoader={ setNinaLoader } />
                        ) : <></>
                    }
                </div>
            </div>
        </Modal>
    )
}

export default PublishModal
