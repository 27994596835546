import Request from "services/Request"


const initialRequest = async (options, setMessage) => {
    
    options.forEach(async ({key, setterFunc}) => {
        try {
            const { statusCode, jsonResponse } = await Request(`/fetch-${ key }`)
            if (statusCode === 200) {
                const { data } = jsonResponse
                setterFunc(data || [])
            } else {
                setMessage({error: `Failed to fetch ${ key }.`})
            }
        } catch (err) {
            setMessage({error: "Server error. Try again after sometime."})
        }
    })
}


const RequestHandler = async (path, payload, setMessage, setNinaLoader, setModalMode, callBackFn) => {
    if ("error" in payload) {
        setModalMode(null)
        return setMessage(payload)
    }
    
    setNinaLoader(true)

    let results;
    try {
        const { domains } = payload
        payload = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v?.length || (typeof v === "number" && v > 0) || (typeof v === "boolean" && v)))
        if (domains) payload["domains"] = domains

        const { statusCode, jsonResponse } = await Request(path, payload)
        if (statusCode === 200) {
            if ("data" in jsonResponse) {
                results = jsonResponse.data.map(item => {
                    let data = { ...item }
                    const taxonomy = data.taxonomy
                    const intent = payload.intents?.filter(i => data.intent.includes(i))[0] || data.intent[0]
                    data.is_positive_emotion = data.is_positive_emotion ? "Positive" : "Negative"
                    data.intent = intent?.length ? intent.charAt(0).toUpperCase() + intent.slice(1).replace("_", " ") : "-"
                    data.taxonomy = taxonomy.label
                    data.score = `${(taxonomy.score * 100).toFixed(2)}%`
                    return data
                }) 
            } else if ("urls" in jsonResponse) {
                results = jsonResponse.urls
            } else {
                setMessage(jsonResponse)
            }
        } else if (statusCode === 400) {
            setMessage({error: "Failed to fetch dataset. Please try again."})
        } else if (statusCode === 204) {
            setMessage({success: "Dataset downloaded successfully."})
        } else {
            setMessage(jsonResponse)
        }
    } catch (err) {
        setMessage({error: "Server error. Try again after sometime."})
    }

    setNinaLoader(false)
    results ? callBackFn(results) : setModalMode(null)
}

export { initialRequest }
export default RequestHandler