import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Request from 'services/Request'
import validatePassword from './utils/validatePassword'
import { Btn, InputBox } from 'components'
import useSnackbar from 'services/useSnackbar'


function SetPassword() {
    const setMessage = useSnackbar()
    const history = useHistory()
    const [ password, setPassword ] = useState("")
    const [ confirmPassword, setConfirmPassword] = useState("")
    const [ passwordError, setPasswordError ] = useState("")
    const [ confirmPasswordError, setConfirmPasswordError ] = useState("")
    const [ inputDisabled, setInputDisabled ] = useState(false)
    const [ inputLabel, btnText] = history && history.location.pathname === "/accounts/set-password" ? [ "Set password", "Activate account" ] : [ "New password", "Change password" ]
    const token = history ? history.location.search.split("token=")[1] : null

    const handleSubmit = async (event) => {
        event.preventDefault()
        const passwordValidation = validatePassword(password)
        setPasswordError(passwordValidation)
        setConfirmPasswordError(password !== confirmPassword && "Passwords do not match")

        if (password === confirmPassword && !passwordValidation) {
            try {
                const headers = { 'Authorization': `Bearer ${token}` }
                const { statusCode, jsonResponse } = await Request("/set-password", { password }, headers)
                if (statusCode === 200) {
                    const { success } = jsonResponse
                    setMessage({ success }, 5)
                    setInputDisabled(true)
                    setTimeout(() => history.push(`/accounts/login`), 2000)
                    setMessage({'success': "Redirecting..."})
                } else if ([401, 403].includes(statusCode)) {
                    setMessage(jsonResponse)
                    token && history.push(`/accounts/activate-account?token=${ token }`)
                } else setMessage({error: "Unable to set password."})
            } catch (error) {
                setMessage({error: "Server error. Try again after sometime."})
            }
        }
    }

    useEffect(() => {
        setPasswordError("")
        setConfirmPasswordError("")
        // eslint-disable-next-line
    }, [password, confirmPassword])

    const handleReload = event => {
        event.preventDefault()
        history.push("/accounts/activate-account?token=" + token)
    }

    useEffect(() => {
        if (token) {
            window.addEventListener("beforeunload", handleReload)
            return () => window.removeEventListener("beforeunload", handleReload)
        }
    }, [ token ])


    return (
        <div>
            <div className="bg--tertiary p-3 mt-3 rounded">
                <span className="pb-2">
                    <strong>
                        Password policy        
                    </strong>
                </span>
                <span className="hints">Minimum 8 characters</span>
                <span className="hints">Should contain both upper and lower case letters</span>
            </div>
            <form className="mt-5" onSubmit={ handleSubmit }>
                <div className="pb-3">
                    <InputBox
                        type="password" 
                        id="set-password" 
                        placeholder={ inputLabel }
                        onChange={e => setPassword(e.target.value.trim())}
                        disabled={ inputDisabled }
                        errorText={ passwordError }
                    />
                </div>
                <div className="mt-3">
                    <InputBox
                        type="password" 
                        id="confirm-password" 
                        placeholder="Confirm password"    
                        onChange={e => setConfirmPassword(e.target.value.trim())} 
                        disabled={ inputDisabled }
                        errorText={ confirmPasswordError }
                    />
                </div>
                <div className="d-flex justify-content-center mt-4 pt-2">
                    <Btn type="submit" disabled={ !password.trim() || !confirmPassword.trim() || inputDisabled || passwordError?.length || confirmPasswordError?.length }>{ btnText }</Btn>
                </div>
            </form>
        </div>
    )
}

export default SetPassword
