import React, { useEffect, useState } from 'react'
import { Prompt, useHistory } from 'react-router-dom'
import DatasetFilters from '../../common/DatasetFilters'
import DownloadModal from '../../common/DownloadModal'
import PreviewModal from '../../common/PreviewModal'
import TaxonomyDropdown from './TaxonomyDropdown'
import PreviewPane from './PreviewPane'
import Customers from './Customers'
import Keywords from './Keywords'
import PublisherDomains from './PublisherDomains'
import AdvertiserDomains from './AdvertiserDomains'
import useSnackbar from 'services/useSnackbar'
import { Btn, NinaDataLoader } from 'components'
import { initialRequest } from '../../utils/RequestHandler'
import { handleCustomDatasetPayload } from '../../utils/PayloadHandlers'


function CustomDataset(props) {
    const { dashboardType } = props
    const history = useHistory()
    const setMessage = useSnackbar()

    const isPublisher = history.location.pathname.endsWith("publisher-view")
    const currentOrg = sessionStorage.getItem("currentOrg")
    const { organisation_id, org_type } = currentOrg && JSON.parse(currentOrg)

    const [ ninaLoader, setNinaLoader ] = useState(false)
    const [ modalMode, setModalMode ] = useState(null)

    const [ customerList, setCustomerList ] = useState([])
    const [ taxonomyList, setTaxonomyList ] = useState([])

    const [ customer_name, setCustomerName ] = useState("")
    const [ taxonomies, setTaxonomies ] = useState([])
    const [ keywords, setKeywords ] = useState("")
    const [ geos, setGeos ] = useState([])
    const [ languages, setLanguages ] = useState([])
    const [ freshness, setFreshness] = useState(["Any"])
    const [ emotions, setEmotions ] = useState([])
    const [ intents, setIntents ] = useState([])
    const [ extras, setExtras ] = useState({})

    const [ payload, setPayload ] = useState(null)
    const [ geoLocations, setGeoLocations ] = useState(null)

    const features = { keywords, taxonomies, geoLocations: geos.map(i => i.split("-")[1].trim()), languages, emotions, intents, freshness }
    const customersProps = { customerList, customer_name, setCustomerName }
    const keywordsProps = { keywords, setKeywords }
    const taxonomyProps = { taxonomies, setTaxonomies, taxonomyList }
    const domainsProps = { extras, setExtras, org_type, dashboardType }
    const modalProps = { payload, geoLocations, features, customer_name, setNinaLoader, setModalMode, extras, organisation_id }
    const previewPaneProps = { ...features, setKeywords, geos, setGeoLocations: setGeos, setLanguages, setEmotions, setIntents }

    const DomainsComponent = isPublisher ? PublisherDomains : AdvertiserDomains
    const doNotLeave = (
        [
            "geoLocations", "intents", "emotions", "languages", "taxonomies", "keywords"
        ].some(i => features[i]?.length) || Object.values(extras)[0]?.length
    )

    const featuresSet1 = {
		geolocation: {
			selected: geos,
			setSelected: setGeos,
			filter: true,
			multi: true,
			selectedFirst: true
		},
		language: {
			selected: languages,
			setSelected: setLanguages,
            multi: true
		}
	}

	const featuresSet2 = {
		intent: {
			selected: intents,
			setSelected: setIntents,
			multi: true
		},
		sentiment: {
			selected: emotions,
			setSelected: setEmotions,
			multi: true
		},
        freshness: {
            selected: freshness,
            setSelected: setFreshness
        }
	}

    let buttons =  [{text: "Download", className: "ml-4"}]
    if (!isPublisher) buttons = [{text: "Preview"}, ...buttons]

    const ModalComponent = {
        Preview: PreviewModal,
        Download: DownloadModal
    }[modalMode]

    const invokeModal = async modalName => {
        const payloads = handleCustomDatasetPayload(features, extras)
        if ("error" in payloads) return setMessage(payloads)
        const [ _payload, _geoLocations ] = payloads

        setPayload(_payload)
        setGeoLocations(_geoLocations)

        setModalMode(modalName)
    }

    useEffect(async () => {
        const options = [{ key: "taxonomies", setterFunc: setTaxonomyList }]
        if (!isPublisher) options.push({ key: "customers", setterFunc: setCustomerList })
        initialRequest(options, setMessage)
    }, [])

    return (
        <div className='m-4 px-2 d-flex size-responsive'>
            <div className='p-0 pt-responsive flex-grow-1 mr-4'>
                { isPublisher ? <></> : <Customers { ...customersProps } /> }
                <Keywords { ...keywordsProps } />
                <TaxonomyDropdown { ...taxonomyProps } />
                <DatasetFilters features={ featuresSet1 } />
                <DatasetFilters features={ featuresSet2 } />
                <DomainsComponent { ...domainsProps } />
                <div className='mt-3 mb-1 text-right'>
                    {
                        buttons.map(item => (
                            <Btn
                                className={ `${item.className} cw-2` }
                                onClick={() => invokeModal(item.text)}
                                disabled={ !(isPublisher || customer_name?.length) }
                            >
                                { item.text }
                            </Btn>
                        ))
                    }
                </div>
            </div>
            <PreviewPane className="p-3 w-100 rounded position-absolute h-100 shadow" { ...previewPaneProps } />
            { ninaLoader && <NinaDataLoader /> }
            { ModalComponent && <ModalComponent { ...modalProps } /> }
            <Prompt
                when={ !!doNotLeave }
                message="Are you sure you want to leave?"
            />
        </div>
    )
}

export default CustomDataset
