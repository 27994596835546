import React from 'react'
import MoreActions from './MoreActions'
import Anchor from './Anchor'
import { calculateBytes, calculateThousands } from './utils/demarcations'
import TruncatedText from './TruncatedText'
import { capitalise } from 'utils/common'


function TableBody(props) {
    const { tableBody, tableHead, responsive, is_custom, actions } = props

    const displayText = (tr, th) => {
        let text = ""
        let val = tr[th.key]
        if(th.prefix && val >= 0) text += th.prefix
        if(th.isBoolean) {
            val = val ? "Yes" : th.default || "No"
        } if(th.isStatus) {
            val = val ? "Active" : "-"
        } else if(th.isBytes) {
            val = calculateBytes(val)
        } else if (th.inThousands) {
            val = calculateThousands(val)
        } else if(th.key === "url") {
            return <Anchor url={ val } />
        } else if(typeof val === "string" && val.length > 100) {
            return <TruncatedText value={ val } length={ 100 } />
        } else if(th.isInt) {
            val = val ? `${val}` : th.default || "0"
        } else if(typeof val === "number") {
            val = val ? val.toFixed(2) : "0"
        } else {
            val = !th.actions ? val || th.default : null
        }

        text += val || "N/A"
        if (th.suffix) text += ` ${th.suffix}`
        if (th.capitalise) text = capitalise(text)
        else if (th.upperCase) text = text.toUpperCase()
        else if (th.lowerCase) text = text.toLowerCase()

        return text
    }

    return (
        <>
           {
                tableBody && tableBody.length ? (
                    tableBody.map((tRow, idx) => (
                        <tr key={ idx }>
                            {
                                tableHead.map((tHead, idy) => (
                                    <td key={ idy } className={ `${ is_custom && "pl-2 pr-1 py-2" } ${ tHead.key === "url" && "w-50" }` }>
                                        <span className='d-flex justify-content-between'>
                                            { displayText(tRow, tHead) }
                                            {
                                                idy === tableHead.length - 1 && actions ? (
                                                    <MoreActions actions={ actions } row={ tRow } />
                                                ) : <></>
                                            }
                                        </span>
                                    </td>
                                ))
                            }

                        </tr>
                    ))
                ) : (
                    <tr className="text-center bg--light">
                         <td colspan={ tableHead.length + 1 } className={  `align-items-center ${ responsive && "p-3" } rounded text-secondary` }>
                            No data to display
                        </td>
                        {/* {
                            tableHead.map((tHead) => (
                                <td>
                                    <span 
                                        className={ `bg-white d-block overflow-auto cell-height${ responsive ? "" : "-sm" }` }
                                    >&nbsp;</span>
                                </td>
                            ))
                        } */}
                    </tr>
                )
                
            } 
        </>
    )
}

export default TableBody
