import { errorMessages, constraints } from 'constants/messages'


const validateEmail = email => {

    const re = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/i
    return re.test(String(email).toLowerCase());
}

const validateInput = (payload) => {
    let regex;
    const [ key, val ] = Object.entries(payload)[0]
    const payloadKey = key.toLowerCase()
    const value = val.trim()

    if (payloadKey === "url") {
        regex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,64}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
        return regex.test(value) ? null : errorMessages({invalid: "url"})
    } else if (payloadKey === "inappurl") {
        const regexes = [/^https:\/\/apps.apple.com\/\S+\/app\/[a-z0-9-]+\/id\S+/g, /^https:\/\/play.google.com\/store\/apps\/details\S+/]
        return regexes.some(item => item.test(value)) ? null : errorMessages({invalid: "url"}) + `${value.startsWith("http") ? ". Only Appstore and Playstore URLs allowed." : ""}`
    } else {
        const constraint = constraints[payloadKey]
        const values = payloadKey === "keywords" ? value.split(",").filter(v => v.trim().length) : value
        const cues = {}

        if (values.length < constraint.min) cues["min"] = constraint.min
        if (values.length > constraint.max) cues["max"] = constraint.max

        if (Object.entries(cues).length) {
            if (payloadKey === "keywords") cues["unit"] = payloadKey
            return errorMessages(cues)
        }

        if (payloadKey === "datasetname") {
            regex = /^[a-zA-Z0-9 _-]+$/g
            return regex.test(value) ? null : errorMessages({special: "dataset name"})
        }

        if (payloadKey === "keywords") {
            return values.every(item => item.trim().length >= 2) ? null : errorMessages({invalid: "keywords length. Each keyword must be of at least 2 characters."})
        }
    }
}


const validateDomain = domain => {

    const re = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i
    return re.test(domain)
}

const validateKeywords = (payload, errorCallbackFn) => {
    const { keywords, min, max } = payload
    
    if (keywords?.trim().length) {
        let keywordsList = keywords.split(",").map(item => item.trim()).filter(item => item?.length)
        if (min && keywordsList.length < min) return errorCallbackFn(`At least ${ min } keywords required.`)
        if (max && keywordsList.length > max) return errorCallbackFn(`Maximum ${ max } keywords allowed.`)
        if (keywordsList.some(item => item.length < 2)) return errorCallbackFn("Each keyword must be of at least 2 characters.")
        return keywordsList
    }
}


export { validateInput, validateEmail, validateDomain, validateKeywords }