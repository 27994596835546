import React, { useState } from 'react'
import Btn from './Btn'
import Modal from './Modal'


function ConfirmationBox(props) {
    const { message, callbackFn, clearFn } = props
    const [ isOpen, setIsOpen ] = useState(true)

    const handleClose = () => {
        setIsOpen(false)
        clearFn()
    }

    const affirmation = () => {
        callbackFn()
        handleClose()
    }

    return (
        <Modal handleClose={ handleClose } isOpen={ isOpen } className="w-25">
            <div className='text-center p-4'>
                <h5 className='text--primary'>{ message }</h5>
                <div className='d-flex justify-content-around mt-5'>
                    <Btn onClick={ affirmation } className="cw-0">Yes</Btn>
                    <Btn onClick={ handleClose } className="cw-0">No</Btn>
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmationBox