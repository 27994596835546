import React, { useState } from 'react'
import { Btn, Modal } from 'components'
import OrganisationSelectionDropdown from './OrganisationSelectionDropdown'


function OrganisationSelectionModal(props) {
    const { callbackFn, _props, organisationModalOpen } = props
    const [ selected, setSelected ] = useState(null)
    
   
    return (
        <Modal isOpen={ organisationModalOpen } className="w-50">
            <div className='p-2'>
                <h5 className='text--primary font-weight-bold'>Multiple accounts available</h5>
                <div className='bg--tertiary p-4 mt-3 rounded text-center'>
                    <div className='d-flex align-items-center px-5 text-muted'>
                        Select account: 
                        <div className='ml-3 flex-grow-1'>
                            <OrganisationSelectionDropdown { ..._props } setSelected={ setSelected } noLabel />
                        </div>
                    </div>
                    <Btn className='mt-4' disabled={ !selected } onClick={ callbackFn } >Confirm</Btn>
                </div>
            </div>
        </Modal>
    )
}

export default OrganisationSelectionModal