import { AltDropDown, Btn, DropDown, Radios } from 'components'
import React, { useEffect, useState } from 'react'


function DV360Targets(props) {
    const { existingDV360s, targets, setTargets, setAddTarget, setEditTargetKey, editTargetKey, published } = props
    const [ accountId, setAccountId ] = useState(null)
    const [ userType, setUserType ] = useState("Partner")
    const [ userId, setUserID ] = useState(null)
    const [ channelType, setChannelType ] = useState("New channel")
    const [ channelId, setChannelId ] = useState(null)
    const [ accountIdError, setAccountIdError ] = useState(null)
    const [ userIdError, setUserIdError ] = useState(null)
    const [ channelIdError, setChannelIdError ] = useState(null)

    useEffect(() => {
        if (editTargetKey) {
            const tempTarget = targets[parseInt(editTargetKey)]
            setAccountId([tempTarget.accountId])
            setUserType(tempTarget.userType)
            setUserID(tempTarget.userId)
            setChannelType(tempTarget.channelType)
            setChannelId(tempTarget.channelId || tempTarget.channelName)
        }
    }, [editTargetKey])


    const updateTargets = (target) => {
        const tempTargets = [ ...targets ]
        editTargetKey ? tempTargets[parseInt(editTargetKey)] = target : tempTargets.push(target)
        setTargets(tempTargets)
    }

    const onClick = () => {
        const target = { accountId: typeof accountId === "string" ? accountId : accountId[0], userType, userId, channelType }
        const channelKey = channelType === "Existing channel" ? "channelId" : "channelName"
        target[channelKey] = channelId
        updateTargets(target)
        setAddTarget(null)
        setEditTargetKey(null)
    }

    useEffect(() => {
        setAccountIdError(null)
        setUserIdError(null)
        setChannelIdError(null)
    }, [accountId, userType, userId, channelId, channelType])


    const AccountIDComponent = () => {
        if (existingDV360s.length === 1) {
            const value = existingDV360s[0]
            setAccountId(value)
            return (
                <input className='mt-1 py-2 px-3 border rounded d-block w-100 bg--secondary' defaultValue={ value } readOnly />
            )
        }
        return (
            <AltDropDown 
                className="border w-100 py-2 d-flex w-100 justify-content-between align-items-center"
                label="DV360 Account"
                placeholder="Select DV360 Account"
                options={ existingDV360s }
                setSelected={ setAccountId }
                selected={ accountId }
            />
        )
    }

    return (
        <div className='w-100 p-4 border rounded mb-4'>
            {
                accountIdError && (
                    <span className='text-danger position-absolute text-right w-75 mt-2 pt-1' data-testid="account-error">
                        { accountIdError }
                    </span>
                )
            }
            <AccountIDComponent />
            <Radios items={ ["Partner", "Advertiser"] } selected={ userType } setSelected={ setUserType } childClassName="col-5 mt-4 ml-2 mb-3" />
            <div className='row align-items-center mr-1'>
                <span className="text-muted ml-2 col-1">ID</span>
                <input className='border rounded px-3 py-1 ml-3 flex-grow-1' data-testid="textbox-user" onChange={e => setUserID(e.target.value)} value={ userId }/>
                {
                    userIdError && (
                        <span className='text-danger position-absolute ml-5 mt-5 pt-1 pl-4' data-testid="user-error">
                            { userIdError }
                        </span>
                    )
                }
            </div>
            <Radios items={ ["New channel"] } selected={ channelType } setSelected={ setChannelType } childClassName="col-5 mt-4 ml-2 mb-3" />
            <div className='row align-items-center mr-1'>
                <span className="text-muted ml-1 col-1 mr-1">{ channelType === "Existing channel" ? "ID" : "Name" }</span>
                <input className='border rounded px-3 py-1 ml-3 flex-grow-1' data-testid="textbox-channel"  onChange={e => setChannelId(e.target.value)} value={ channelId } />
                {
                    channelIdError && (
                        <span className='text-danger position-absolute ml-5 mt-5 pt-1 pl-4' data-testid="channel-error">
                            { channelIdError }
                        </span>
                    )
                }
            </div>
            <div className='text-right mt-4'>
                <Btn className='cw-1' onClick={ onClick } disabled={ !accountId?.length || !userId?.length || !channelId?.length  }>Save</Btn>
            </div>
        </div>
    )
}

export default DV360Targets