import OverviewIcon from 'assets/icons/sidebar/OverviewIcon.svg';
import OverviewIconActive from 'assets/icons/sidebar/OverviewIcon-active.svg'
import CreateDatasetIcon from 'assets/icons/sidebar/CreateDataset.svg';
import CreateDatasetActive from 'assets/icons/sidebar/CreateDataset-active.svg';
import UsersManagementIcon from 'assets/icons/sidebar/UsersIcon.svg';
import UsersManagementIconActive from 'assets/icons/sidebar/UsersIcon-active.svg'
import AccountDetailsIcon from 'assets/icons/sidebar/AccountDetails.svg';
import AccountDetailsIconActive from 'assets/icons/sidebar/AccountDetails-active.svg'
import Overview from 'app/Dashboard/Overview';
import UsersManagement from 'app/Dashboard/UsersManagement';
import AccountDetails from 'app/Dashboard/AccountDetails';
import { CustomSearch, KeywordsTargeting, URLTargeting, DescriptionTargeting, TaxonomyTargeting, CustomDataset } from 'app/Dashboard/CreateDataset/Web'


const cdWebPages = filters => [
    {
        text: "URL Targeting",
        path: "/url-targeting",
        key: "url",
        component: URLTargeting
    }, {
        text: "Description Targeting",
        path: "/description-targeting",
        key: "description",
        component: DescriptionTargeting
    }, {
        text: "Keywords Targeting",
        path: "/keywords-targeting",
        key: "keywords",
        component: KeywordsTargeting
    }, {
        text: "Taxonomy Categories",
        path: "/taxonomy-categories",
        key: "taxonomyCategories",
        component: TaxonomyTargeting
    }, {
        text: "Custom Search",
        path: "/custom-search",
        key: "custom",
        component: CustomSearch
    }, {
        text: "Custom Dataset Preparation",
        path: "/custom-dataset-preparation",
        key: "customDataset",
        component: CustomDataset,
        internalOnly: true
    }
].filter(item => !filters.some(i => i in item))


const createDatasetPages = filters =>  [
    {
        text: "Web",
        path: "/web",
        key: "web",
        menu: cdWebPages(filters)
    }, {
        text: "In-app",
        path: "/in-app",
        key: "inApp",
        disabled: true
    }, {
        text: "CTV",
        path: "/ctv",
        key: "ctv",
        disabled: true
    }
].filter(item => !filters.some(i => i in item))


const dashboardPages = filters => [
    {
        icon: OverviewIcon,
        selected: OverviewIconActive,
        text: "Overview",
        path: "/overview",
        key: "overview",
        component: Overview
    },
    {
        icon: CreateDatasetIcon,
        selected: CreateDatasetActive,
        text: "Create Dataset",
        path: "/create-dataset",
        key: "createDataset",
        menu: createDatasetPages(filters),
        publisherExclude: true
    },
    {
        icon: CreateDatasetIcon,
        selected: CreateDatasetActive,
        text: "Create Dataset",
        path: "/publisher-view",
        key: "publisherView",
        component: CustomDataset,
        advertiserExclude: true
    },
    {
        icon: UsersManagementIcon,
        selected: UsersManagementIconActive,
        text: "User Management",
        path: "/user-management",
        key: "userManagement",
        component: UsersManagement,
        hiddenFromMembers: true
    }, 
    {
        icon: AccountDetailsIcon,
        selected: AccountDetailsIconActive,
        text: "Account Details",
        path: "/account-details",
        key: "accountDetails",
        component: AccountDetails
    }
].filter(item => !filters.some(i => i in item))

export default dashboardPages