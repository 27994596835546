import React, { useEffect, useState } from 'react'
import { FileUploader, Radios } from 'components'
import { parseCsvfile } from 'utils/parseFiles'
import { validateDomain } from 'utils/validations'
import { compareArrays, pluralise } from 'utils/common'
import useSnackbar from 'services/useSnackbar'


function AdvertiserDomains(props) {
    const setMessage = useSnackbar()
    const { extras, setExtras } = props
    const [ domainsType, setDomainsType ] = useState("")
    const [ uploadedFile, setUploadedFile ] = useState(null)
    const [ domains, setDomains ] = useState(null)
    const [ errorMessage, setErrorMessage ] = useState("")

    const validateFile = file => file.type !== "text/csv" && {error: "Invalid file type. CSV required."}
    const fileUploadProps = { setUploadedFile, validateFile }
    
    const parseCsvData = csvData => {
        if (csvData) {
            const colArray = Object.keys(csvData[0])
            if (compareArrays(colArray.map(i => i.toLowerCase()), ["domain"])) {
                const colName = colArray[0]
                const validData = csvData.filter(i => validateDomain(i[colName]))
                const lengthDiff = csvData.length - validData.length
                const validDomains = validData.map(i => i[colName])

                lengthDiff && setMessage({
                    info: `${lengthDiff} ${pluralise("domain", lengthDiff)} rejected due to incorrect format.`
                })                

                if (validDomains) {
                    const tempDomains = [0, 1, 2].map(i => 
                        validDomains.length > 7 ? (
                            [
                                i, validDomains.slice(
                                    parseInt((i * validDomains.length) / 3),
                                    parseInt(((i + 1) * validDomains.length) / 3)
                                ).join("\n")
                            ]
                        ) : ([ i, i ? "" : validDomains.join("\n") ]))
                    setDomains(Object.fromEntries(tempDomains))
                }    
            } else {
                setMessage({error: "Incorrect data column/s. Kindly refer to the downloaded template."})
            }
        }
    }

    useEffect(() => {
        if (domains && typeof domains === "object") {
            const tempDomains = []
            const tempExtras = { ...extras }

            if (!domainsType?.length) return
            const domainsKey = `domains_${domainsType.toLowerCase()}`
            
            Object.values(domains).forEach(item => {
                if (item?.length) {
                    const values = item.trim().split("\n").map(i => i.trim()).filter(i => i?.length)
                    if (!values.every(i => validateDomain(i))) { 
                        setErrorMessage("Invalid domains found which will be dropped during the request.")
                    }
                    tempDomains.push(...(values.filter(i => validateDomain(i))))
                }
            })
            delete tempExtras["domains_blacklist"]
            delete tempExtras["domains_whitelist"]
            tempExtras[domainsKey] = tempDomains
            setExtras(tempExtras)
        }
    }, [ domains, domainsType ])


    useEffect(() => {
        setErrorMessage("")
        setDomains({0: "", 1: "", 2: ""})
        if (uploadedFile) {
            parseCsvfile(uploadedFile, parseCsvData)
        }
    }, [ uploadedFile ])

    
    return (
        <div className='mb-responsive'>
            <span htmlFor='dataset-name' className='label-text d-flex justify-content-between pb-0 pt-2 mb-0'>
                Domains
            </span>
            <div className='d-flex align-items-center mb-3'>
                <div className='w-50'>
                    <Radios items={ ["Whitelist", "Blacklist"] } selected={ domainsType } setSelected={ setDomainsType } childClassName="col-6" />
                </div>
                <FileUploader { ...fileUploadProps } btnLabel="Import" className="ml-3" btnClassName="btn bg-secondary text--white px-3 fix-width-btn" accept=".csv" disabled={ !domainsType?.length } />
            </div>
            <div className={ `border d-flex rounded d-flex mt-2 w-100 p-2 ${!domainsType?.length && "bg-light"}` } 
                onMouseEnter={() => setErrorMessage("")}
                onMouseLeave={() => errorMessage?.length && setMessage({error: errorMessage})}
            >
                {
                    domains && Object.entries(domains).map(([k, v]) => (
                        <textarea 
                            className='border-0 flex-fill'
                            rows={ 7 }
                            value={ v }
                            disabled={ !domainsType?.length }
                            onChange={e => {
                                const tempDomains = { ...domains }
                                tempDomains[k] = e.target.value
                                setDomains(tempDomains)
                                setErrorMessage("")             
                            }}
                        />  
                    ))
                }
            </div>
        </div>
    )
}

export default AdvertiserDomains