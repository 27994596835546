import { faCheckSquare, faChevronDown, faChevronRight, faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'


function TaxonomyItem(props) {
    const { dropDownItem, taxonomies, setTaxonomies, idx } = props
    const [ open, setOpen ] = useState(false)
    const [ parentChecked, setParentChecked ] = useState(null)
    const [ selected, setSelected ] = useState([])
    const [ hovered, setHovered ] = useState("")

    const hoverClass = "bg--grey text--primary rounded pointer"
    const textValue = dropDownItem[0]

    const handleSelection = (item) => {
        const taxonomy = `${ dropDownItem[0] }_${ item }`
        taxonomies.includes(taxonomy) ? setTaxonomies(taxonomies.filter(i => i !== taxonomy)) : setTaxonomies([ ...taxonomies, taxonomy ])
        selected.includes(item) ? setSelected(selected.filter(i => i !== item)) : setSelected([ ...selected, item ])
    }

    useEffect(() => {
        if (parentChecked !== null) {
            if (parentChecked) {
                setTaxonomies([ ...taxonomies, ...dropDownItem[1].map(item => `${ textValue }_${ item }`) ])
                setSelected(dropDownItem[1])
            } else {
                setTaxonomies(taxonomies.filter(item => !dropDownItem[1].map(item => `${ textValue }_${ item }`).includes(item) ))
                setSelected([])
            }
        }
    }, [ parentChecked ])


    return (
        <div className='px-3'>
            <div className={ `d-flex align-items-center ${ idx && "border-top" } py-1` }>
                <div className='chevron-container'>
                    <FontAwesomeIcon icon={ open ? faChevronDown : faChevronRight } size="sm" className='text--secondary pointer' onClick={() => setOpen(!open)} />
                </div>
                <div onClick={() => setParentChecked(!parentChecked)}
                    onMouseEnter={() => setHovered(textValue)}
                    onMouseLeave={() => setHovered("")}
                    className={ `d-flex align-items-center ${ hovered === textValue && hoverClass } w-100 p-1` }
                >
                    <div className='mx-2 checkbox-container bg-white' >
                        {
                            selected?.length && selected.length < dropDownItem[1].length ? (
                                <FontAwesomeIcon icon={ faMinusSquare } size="lg" className='text--primary' />
                            ) : parentChecked || selected?.length === dropDownItem[1].length ? (
                                <FontAwesomeIcon icon={ faCheckSquare } size="lg" className='text--primary' />
                            ) : (
                                <div className=''></div>
                            )
                        }
                    </div>
                    <span className='ml-1'>{ textValue }</span>
                </div>
            </div>
            {
                open ? (
                    <div>
                        {
                            dropDownItem[1].map(i => (
                                <div onClick={() => handleSelection(i)}
                                    onMouseEnter={() => setHovered(`_${i}`)}
                                    onMouseLeave={() => setHovered("")}
                                    className={ `d-flex align-items-center ${ hovered === "_" + i && hoverClass } w-90 p-1 mx-4 border-top` }
                                >
                                    <div className='mx-3 bg-white checkbox-container'>
                                        {
                                            selected.includes(i) ? (
                                                <FontAwesomeIcon icon={ faCheckSquare } size="lg" className='text--primary' />
                                            ) : (
                                                <div className=''></div>
                                            )
                                        }
                                    </div>
                                    <span>{ i }</span>
                                </div>
                            ))
                        }
                    </div>
                ) : <></>
            }

        </div>
    )
}

export default TaxonomyItem