import React, { useState } from 'react'
import { useHistory } from 'react-router';
import { InputBox } from 'components';
import { pathFor } from 'constants/apiPaths';
import { validateEmail } from 'utils/validations'
import Request from 'services/Request';
import useSnackbar from 'services/useSnackbar';


function AddUser(props) {
    const { organisation_id } = props?.currentOrg
	const setMessage = useSnackbar()
    const history = useHistory()
    const [ name, setName ] = useState("")
    const [ email, setEmail ] = useState("")
    const [ companyName, setCompanyName ] = useState("")
    const [ emailError, setEmailError ] = useState(null)
    const [ nameError, setNameError ] = useState(null)
    const [ companyNameError, setCompanyNameError ] = useState(null)

    const handleSubmit = async (event) => {
        event.preventDefault()
        let noErrors = true

        if (!validateEmail(email)) { noErrors = false; setEmailError("Invalid email."); }
        if (name.length < 3 || name.length > 40) { noErrors = false; setNameError("Length must be between 3 and 40"); }
        if (companyName.length < 3 || companyName.length > 20) { noErrors = false; setCompanyNameError("Length must be between 3 and 20"); }

        if (noErrors) {
            try {
                const { statusCode, jsonResponse } = await Request(pathFor.AddMember, { name, email, company_name: companyName, organisation_id })
                setMessage("errors" in jsonResponse && jsonResponse.errors.json.email.length ? {"error": jsonResponse.errors.json.email[0]} : jsonResponse)
                if (statusCode === 201) history.push("/dashboard/user-management")
                else setMessage(jsonResponse)
            } catch {
                setMessage({error: "Server error. Try again after sometime."})
            }
        }
    }


    return (
        <div className="mx-3 mt-3">
            <form className="col-xl-4 col-lg-5 col-md-6 col-sm-7 col-8 py-3 px-3" onSubmit={ handleSubmit }>
                <p className="label-text mb-1">ADD NEW USER</p>
                <div className='my-4'>
                    <InputBox type="text" id="client-name" 
                        placeholder="Name"
                        errorText={ nameError }
                        onChange={e => { setNameError(null); setName(e.target.value.trim()); }}
                    />
                </div>
                <div className='my-4'>
                    <InputBox type="email" id="client-email"
                        placeholder="Email"
                        errorText={ emailError } 
                        onChange={e => { setEmailError(null); setEmail(e.target.value.trim()); }} 
                    />
                </div>
                <div className='my-4'>
                    <InputBox type="text" id="client-company-name"
                        placeholder="Company name"
                        errorText={ companyNameError } 
                        onChange={e => { setCompanyNameError(null); setCompanyName(e.target.value.trim()); }} 
                    />
                </div>
                <div className="d-flex justify-content-end my-4">
                    <button type="button" className="btn bg-white px-3 py-2 btn-cancel text--primary" onClick={() => history.push("/dashboard/user-management")}>Cancel</button>
                    <button type="submit" className="btn bg--primary text--white py-2 px-3 ml-3" disabled={ !(name.length && email.length)}>Send activation link</button>
                </div>
            </form>
        </div>
    )
}

export default AddUser
