import React, { useState, useEffect } from 'react';
import useWindowDimensions from 'utils/windowDimensions';
import SidebarItem from './SidebarItem';


function Sidebar(props) {
    const { pages, collapsed, setTitle, setCrumbs } = props
    const [ collapsible, setCollapsible ] = useState(!!sessionStorage.getItem("collapsed") || false);
    const { width } = useWindowDimensions();
    collapsed ? sessionStorage.setItem("collapsed", 1) : sessionStorage.removeItem("collapsed") 

    useEffect(() => setCollapsible(width < 1368), [ width ])
    const collapse = collapsed || collapsible

    return (
        <div className={ `bg--secondary shadow sidebar d-flex flex-column justify-content-between ${ collapse && 'collapsed'}` }>
            <div>
                { collapse || props.upperProp }
                <div className={ `ml-2 my-3` }>
                    {
                        pages.map((item, idx) => (
                            <div key={ idx } className="ml-1">
                                <SidebarItem item={ item } collapse={ collapse } setTitle={ setTitle } setCrumbs={ setCrumbs }/>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Sidebar
