import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Request from 'services/Request'
import { validateEmail } from 'utils/validations'
import { Btn, InputBox } from 'components'
import useSnackbar from 'services/useSnackbar'


function ResetPassword() {
    const setMessage = useSnackbar()
    const history = useHistory()
    const [ email, setEmail ] = useState("")
    const [ submitted, setSubmitted ] = useState(false)
    const btnText = submitted ? "Close this window" : "Send reset link to email"

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!validateEmail(email)) {
            setMessage({'error': "Invalid email"})
            return false
        }

        try {
            const { statusCode, jsonResponse } = await Request("/reset-password", { email })
            if (statusCode === 200) setSubmitted(true)
            setMessage(jsonResponse)
        } catch (error) {
            setMessage({error: "Server error. Try again after sometime."})
        }
    }

    return (
        <div>
            <div className="bg--tertiary p-3 mt-3 rounded">
                <span className="pb-2">
                    <strong>
                        Steps to reset password      
                    </strong>
                </span>
                <span className="hints">1. Enter your registered email address</span>
                <span className="hints">2. Follow the link received in the mail, to setup new password</span>
            </div>
            <form className="mt-5" onSubmit={ handleSubmit }>
                <div className="  ">
                    <InputBox 
                        type="email" 
                        id="email" 
                        placeholder="Email"
                        onChange={e => setEmail(e.target.value.trim())}
                        disabled={ submitted }
                    />
                </div>
                <div className="d-flex justify-content-center mt-3 py-2">
                    <Btn 
                        type="submit" 
                        disabled={ !email.trim() } 
                        onClick={() => submitted && history.push("/accounts/login")}
                    >
                        { btnText }
                    </Btn>
                </div>
                <a href="/accounts/login" className={ `d-${ submitted ? "none" : "block"} text-center` }>Click here to Login</a>
            </form>
        </div>
    )
}

export default ResetPassword
