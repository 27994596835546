import { InputBox } from 'components'
import React, { useState } from 'react'


function Customers(props) {
    const { customerList, setCustomerName, customer_name } = props
    const [ customerSuggestions, setCustomerSuggestions ] = useState([])

    
    const handleCustomerName = event => {
        const c_name = event.target.value
        setCustomerName(c_name)
        setCustomerSuggestions(c_name?.trim().length ? customerList?.filter(item => item.toLowerCase().includes(c_name.trim().toLowerCase())) : "")
    }

    return (
        <div className='p-0 mb-responsive position-relative'>
            <InputBox 
                type='text' 
                placeholder="Customer name *"
                className='border customer-name' 
                onChange={ handleCustomerName }
                value={ customer_name }
            />
            {
                customerSuggestions?.length ? (
                    <div className='p-2 border-0 rounded position-absolute w-100 bg-white customer-suggestions shadow-lg'>
                        {
                            customerSuggestions.map(item => (
                                <span className='d-block pointer px-2 py-1 rounded' onClick={() => { setCustomerSuggestions([]); setCustomerName(item);}} >{ item }</span>
                            ))
                        }
                    </div>
                ) : <></>
            }
        </div>
    )
}

export default Customers