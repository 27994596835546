
const generateExportData = (head, body) => {
    let data = []
    data.push(head.map(col => col.title))
    body.forEach(row => {
        data.push(
            head.map(col => row[col.key])
        )
    })
    
    return data
}

export default generateExportData