import React, { useEffect, useState }  from 'react';
import DateSelector from './DateSelector';
import Request from 'services/Request'
import { pathFor } from 'constants/apiPaths';
import getDates from 'utils/getDates';
import Helper from './Helper';
import useSnackbar from 'services/useSnackbar';


function Banner(props) {
    const setMessage = useSnackbar()
    const { bannerKey, title, metrics, datewise, organisation_id } = props;
    const [ metricValues, setMetricValues ] = useState({})
    const [ dateDelta, setDateDelta ] = useState(7)

    // eslint-disable-next-line
    useEffect(async () => {
        setMetricValues({})
        const payload = datewise ? getDates(dateDelta) : {}
        try {
            const path = `${pathFor[bannerKey]}${bannerKey === "DatasetActivities" ? "/" + organisation_id : ""}`
            const { statusCode, jsonResponse } = await Request(path, payload)
            if (statusCode === 200) setMetricValues(jsonResponse)
            else if (statusCode === 401) setMessage(jsonResponse)
        } catch(err) {
            setMessage({error: "Server error. Try again after sometime."})
        }
    }, [ bannerKey, dateDelta ])

    return (
        <div className="col shadow p-0 rounded-widget mx-3">
            <div className="bg--primary text-white d-flex justify-content-between align-items-center rounded-header pl-4 pr-2">
                <h6 className="font-weight-bold py-3 m-0">{ title }</h6>
                {
                    datewise && <DateSelector variant="white" setDateDelta={ setDateDelta } />
                }
            </div>

            <div className='rounded-body px-4 py-2 text-muted bg-white'>
                {
                    metrics.map((item, idx) => (
                        <div className="d-flex justify-content-between" key={ idx }>
                            <p className="d-flex m-0 py-2">
                                { item.text }
                                { item.helper && (
                                    <span className="pt-1">
                                        <Helper itemKey={ item.key } variant="white"/> 
                                    </span>
                                )}
                            </p>
                            <p className="m-0 py-2">
                                { !Object.entries(metricValues).length ? "--" : item.isPercentage ? `${(metricValues[item.key] || item.defaultValue) * 100}%` : metricValues[item.key] || item.defaultValue } 
                                { item.unit && metricValues && metricValues[item.key] ? <span className="unit">{ item.unit }</span> : undefined}
                            </p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Banner;
