import React, { useEffect, useState } from 'react';
import { accountDetailsTable } from 'constants/tableConstants'
import DSPLinkedAccounts from './DSPLinkedAccounts';
import DomainsSection from './DomainsSection';


function AccountDetails(props) {
    const { currentOrg, currentOrgChanged, dashboardType } = props
    const { organisation_id } = currentOrg
    const [ details, setDetails ] = useState(JSON.parse(sessionStorage.getItem("accountDetails")))
    
    useEffect(async () => {
        const accountDetails = JSON.parse(sessionStorage.getItem("accountDetails"))
        setDetails([accountDetails])
    }, [ organisation_id, currentOrg, currentOrgChanged ])

    return (
        <div className="m-3 p-3">
            <p className="label-text">USER DETAILS</p>
            {
                details ? (
                    <>
                        <div className='border w-100 border rounded row mx-0'>
                            {
                                accountDetailsTable.map((item, idx) => (
                                    <div className={ `col-${ 3 + idx} border-left p-0` }>
                                        <div className='text--primary px-3 py-2 border-bottom'>{ item.title }</div>
                                        <div className='px-3 py-2'>{ details[0]?.[item.key] }</div>
                                    </div>
                                ))
                            }
                        </div>
                        <span className="d-block pt-2 pl-1">Note: Contact your Account Manager for any changes.</span>
                    </>
                ) : <></>
            }
            <DomainsSection currentOrg={ currentOrg } dashboardType={ dashboardType } />
            { dashboardType === "advertiser" ? <DSPLinkedAccounts organisation_id={ organisation_id } /> : <></> }
        </div>
    )
}

export default AccountDetails
