import React, { useEffect, useState } from 'react'
import {ReactComponent as LogoWhite} from 'assets/images/logo/LogoWhite.svg'


function NinaDataLoader(props) {
    const { text } = props
    const [ ellipses, setEllipses ] = useState(0)

    useEffect(() => {
        setTimeout(() => setEllipses(ellipses + 1), 500)
    }, [ellipses])
    
    return (
        <div className="ninadata-loader position-fixed d-flex justify-content-center align-items-center w-100 mr-2" >
            <LogoWhite /> <span className="loading-text ml-2">{ typeof text === "string" ? text : "Loading" }<span className="ellipses">{ [...Array(ellipses % 4).keys()].map(_ => ".") }</span></span>
        </div>
    )
}

export default NinaDataLoader
