import React, { useEffect, useState } from 'react'
import { DataTable } from 'components'
import { PieChart } from 'components/Charts'
import { pathFor } from 'constants/apiPaths'
import useSnackbar from 'services/useSnackbar'
import Request from 'services/Request'


function DomainsReport(props) {
    const setMessage = useSnackbar()
    const { organisation_id } = props
    const [ domainsData, setDomainsData ] = useState([])

    const tableHead = [
        {
            title: "Domain name",
            key: "domain_name",
            sortable: true
        },
        {
            title: "URLs count",
            key: "urls_count",
            sortable: true,
            isInt: true
        }
    ]

    useEffect(async () => {
		try {
            const { statusCode, jsonResponse } = await Request(pathFor.DomainsData, { organisation_id })
            if (statusCode === 200) {
				const { data } = jsonResponse
                setDomainsData(data)
            } else {
                setMessage(jsonResponse)
            }
        } catch (err) {
            setMessage({error: "Server error. Try again after sometime."})
        }
	}, [])

    let chartData = []
    const colors = ["#5270a3", "#627eac", "#728cb6", "#839abf", "#94a8c8", "#a5b6d2", "#b7c4db", "#c8d3e4", "#dae1ed", "#edf0f6", "#aaa"]
    if (domainsData?.length) {
        chartData = domainsData.sort((a, b) => b.urls_count - a.urls_count)
        chartData = chartData.map(item => ({
            label: item.domain_name,
            value: item.urls_count
        }))

        chartData = chartData.filter(item => item.value)
        chartData = [ ...chartData.slice(0, 10) ]

        if (chartData?.length > 10 ) chartData = [ ...chartData, { label: "Others", value: chartData.slice(10).reduce((acc, curr) => acc + curr.value, 0)} ]
    }


    return (
        <div className='d-flex justify-content-between cm-2 mb-4'>
            <div className="shadow rounded-widget w-49 d-flex flex-column">
                <div className="bg--primary text-white d-flex justify-content-between align-items-center rounded-header pl-4">
                    <h6 className="font-weight-bold py-3 m-0">DOMAINS</h6>
                </div>
            
                <div className="bg-white p-4 rounded-body flex-grow-1 h-100">
                    <DataTable tableData={{ tableHead, tableBody: domainsData }} noExport is_custom />
                </div> 
            </div>

            <div className='shadow rounded-widget w-49 px-4 py-5 bg-white border-top ml-4'>
                <PieChart
                    title="  URLs per Domain"
                    chartData={ chartData }
                    legendDirection="left"
                    dimensions={{ height: "500", width: "400" }}
                    labelOffset={ 70 }
                    layout={{
                        padding: {
                            right: 30,
                            left: 30
                        }
                    }}
                    colors={ colors }
                />
            </div>
        </div>
    )
}

export default DomainsReport