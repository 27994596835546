import React, { useState } from 'react'
import PageLength from './PageLength'
import Pagination from './Pagination'
import Table from './Table'
import InfoDisplay from './InfoDisplay'
import ExportButton from './ExportButton'
import sortData from './utils/sortData'
import generateExportData from './utils/generateExportData'
import filterData from './utils/filterData'


function DataTable(props) {
    const { tableData, exportBtnType, exportFileName, filterBy, extraButton, onExportClick, unresponsive, is_custom } = props
    const [ sortProps, setSortProps ] = useState(null)
    const [ currentPage, setCurrentPage ] = useState(1)
    const [ pageLength, setPageLength ] = useState(10)

    const tableHead = tableData.tableHead
    const tempBody =  filterBy ? filterData(tableData.tableBody || [], filterBy) : tableData.tableBody
    const tableRows = tempBody ? sortData(tempBody, sortProps) : []

    const total = tableRows.length
    const start = total ? pageLength * (currentPage - 1) : 0
    const count = pageLength * currentPage
    const end = !total ? 0 : total < count ? total : count  
    const tableBody = tableRows.slice(start, end)

    const exportData = generateExportData(tableHead, tableBody)
    const paginationProps = { total, pageLength, currentPage, setCurrentPage }
    
    const exportButtonProps = { exportData, exportFileName, extraButton, onExportClick, variant: exportBtnType }


    return (
        <div>
            <div className='d-flex justify-content-between align-items-end mb-3'>
                <PageLength pageLength={ pageLength } setPageLength={ setPageLength }/>
                <div className='d-flex align-items-center'>
                    { props.children }
                    { exportFileName || extraButton ? <ExportButton { ...exportButtonProps } /> : <></> }
                </div>
            </div>

            <Table 
                className={ `table ${is_custom && "table-sm"} table-bordered rounded` }
                tableData={ { tableHead, tableBody } } 
                setSortProps={ setSortProps }
                responsive={ !unresponsive }
                is_custom={ is_custom }
            />

            <div className={ `d-flex justify-content-between mt-3` }>
                <InfoDisplay info={ { start, end, total } } />
                <Pagination { ...paginationProps } className="text-right"/>
            </div>
        </div>
    )
}

export default DataTable
