import React from 'react'
import PreviewPaneComponent from './PreviewPaneComponent'


function PreviewPane(props) {
    const { className, setKeywords, geos, geoLocations, setGeoLocations } = props

    const paneComponents = [
        {
            title: "Keywords",
            size: "lg",
            itemsList: props.keywords.split(",").map(i => i.trim()).filter(i => i?.length),
            updateItems: keywordsList => setKeywords(keywordsList.join(", "))
        }, {
            title: "Taxonomy categories",
            size: "lg",
            itemsList: props.taxonomies,
            colSize: 12
        }, {
            title: "Geolocations",
            size: "md",
            itemsList: geoLocations,
            updateItems: geoList => setGeoLocations(geos.filter(geo => geoList.includes(geo.split("-")[1]?.trim()))),
            colSize: 3
        }, {
            title: "Languages",
            size: "sm",
            itemsList: props.languages,
            updateItems: props.setLanguages
        }, {
            title: "Emotions",
            size: "sm",
            itemsList: props.emotions,
            updateItems: props.setEmotions
        }, {
            title: "Intents",
            size: "md",
            itemsList: props.intents,
            updateItems: props.setIntents,
            colSize: 4
        }
    ]


    return (
        <div className='col-4 p-0 output-view rounded'>
            <div className={ `${className} ${["keywords", "taxonomies", "emotions", "languages", "intents", "geoLocations"].some(item => props[item]?.length) || "d-none"}` }>
                {
                    paneComponents.slice(0, 2).map(paneComponent => (
                        <PreviewPaneComponent { ...paneComponent } />
                    ))
                }
                <div className='h-50'>
                    {
                        paneComponents.slice(2).map(paneComponent => (
                            <PreviewPaneComponent { ... paneComponent } />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default PreviewPane
