import React, { useEffect, useState } from 'react'
import './styles.css'
import Routes from './Routes'
import { SnackbarsContext } from 'services/useSnackbar'
import { Snackbar } from 'components'


function App() {
    const [ snackbars, setSnackbars ] = useState([])

    const handleSnackbarRemoval = item => {
        setSnackbars(snackbars.map(
            snackbar => item.timeStamp === snackbar.timeStamp ? { ...snackbar, closed: true } : snackbar )
        )
    }

    const handleSnackbarCreation = (item, seconds) => {
        if (item) {
            const timeStamp = + (new Date((new Date()).getTime() + (seconds || 10) * 1000))
            setSnackbars([ ...snackbars, { ...item, timeStamp } ])
        }
    }

    useEffect(() => {
        if (snackbars?.length) {
            snackbars.every(snackbar => snackbar.closed) && setSnackbars([])
        }
    }, [ snackbars ])

    return (
        <>
            <SnackbarsContext.Provider value={ handleSnackbarCreation }>
                <Routes />
            </SnackbarsContext.Provider>
            <div className='snackbars'>
                {
                    snackbars.filter(item => item && !item.closed).map((item, idx) => (
                        <Snackbar item={ item } closeSnackbar={ handleSnackbarRemoval } className={ idx === snackbars.length - 1 ? "pop-in" : "" } />
                    ))
                }
            </div>
        </>
    )
}

export default App
export { SnackbarsContext }