
// eslint-disable-next-line
export default function (password, lengthOnly) {
    if ( password.length < 8 ) return "Minimum 8 characters required"
    if (!lengthOnly) {
        if ( !password.match(/[a-zA-Z]/g) ) return "Alphabets required"
        if( password === password.toLowerCase() ) return "At least one upper case letter required"
        if( password === password.toUpperCase() ) return "At least one lower case letter required" 
    }
}
