import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { capitalise } from 'utils/common'
import SidebarSubMenu from './SidebarSubMenu'


function SidebarItem(props) {
    const { item, setTitle, collapse } = props
    const { text, menu } = item
    const match = useRouteMatch()
    const history = useHistory()
    const [ displayProp, setDisplayProp ] = useState("d-inline")
    const [ isSelected, setIsSelected ] = useState(false)
    const [ isHovered, setIsHovered ] = useState(false)
    const [ isChildActive, setIsChildActive ] = useState(false)
    const highlight = isHovered || isChildActive

    useEffect(() => {
        if (collapse) {
            setDisplayProp("d-none")
        } else {
            setTimeout(() => setDisplayProp("d-inline"), 100)
        }
    }, [ collapse ])

    useEffect(() => {
        if (isSelected) {
            let pageTitle = text
            
            if (text === "Create Dataset") {
                const splittedPathname = history.location.pathname.split("/")
                pageTitle = splittedPathname[splittedPathname.length - 1].split("-").map(i => capitalise(i)).join(" ")
            }
            setTitle(pageTitle)
        }
    }, [ isSelected ])

    const handleClick = event => {
        menu && event.preventDefault()
    }


    return (
        <div className={ menu && "hoverable-sidebar-menu position-relative" }>
            <NavLink key={ item.key } 
                to={ `${match.url}${item.path}` } 
                className={ `sidebar-menu-item p-0 ${ isSelected ? `text-selected ${collapse && "bg--secondary"}` : "none" } flex-grow-1` } 
                isActive={match => setIsSelected(!!match)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={ handleClick }
            >
                <div className={ `d-flex align-items-center py-2 pl-3 ${ !isSelected && highlight && "hovered-item" }`}>
                    <img src={ isSelected ? item.selected : item.icon } alt="menu icon" height="24" width="22"/>
                    <span className={ `ml-2 ${ displayProp } ${ isSelected ? "text--primary" : "text-muted" }` }>{ text }</span>
                </div>
            </NavLink>
            {
                menu ? (
                    <>
                        <FontAwesomeIcon icon={ faCaretRight} className="caret-right" />
                        <SidebarSubMenu menu={ menu } 
                            parentPath={ `${match.url}${item.path}` } 
                            highlightParent={ setIsChildActive }
                            displaySignal={ isHovered } 
                            setTitle={ setTitle }
                        /> 
                    </>
                ) : <></>
            }
        </div>
    )
}

export default SidebarItem
