import React, { useState } from 'react'
import TableHead from './TableHead'
import TableBody from './TableBody'


function Table(props) {
    const { tableData, className, setSortProps, responsive, is_custom } = props
    let { tableHead, tableBody } = tableData
    const [ scrolled, setScrolled ] = useState(false)
    const thClassName = scrolled && "bg-white"

    const actions = tableHead.filter(tHead => tHead.actions)?.[0]?.actions
    tableHead = tableHead.filter(tHead => !tHead.actions)    
    
    return (
        <div className={ `${ responsive && "table-responsive tbr" } ${ is_custom && "custom-tbr" } ${ tableBody?.length || "tbr-scroll-hidden no-data" } border rounded bg-white` } onScroll={(e) => setScrolled(!!e.target.scrollTop)}>
            <table className={ `${className} mb-0` }>
                <thead className='border'>
                    <tr className="text--primary">
                        <TableHead tableHead={ tableHead } setSortProps={ setSortProps } className={ thClassName } is_custom={ is_custom } />
                    </tr>
                </thead>
                <tbody>
                    <TableBody tableBody={ tableBody } tableHead={ tableHead } responsive={ responsive } is_custom={ is_custom } actions={ actions }/> 
                </tbody>
            </table>
        </div>
    )
}
                   
export default Table
