
const getDates = (delta) => {
    let dateStart, dateEnd;
    const date = new Date()

    if (delta === 0) {
        dateStart = date.toISOString().split("T")[0]
        date.setDate(date.getDate() + 1)
        dateEnd = date.toISOString().split("T")[0]
    } else {
        dateEnd = date.toISOString().split("T")[0]
        date.setDate(date.getDate() - delta)
        dateStart = date.toISOString().split("T")[0]
    }

    return { "date-start": dateStart, "date-end": dateEnd };
};

export default getDates;