import React, { useEffect, useState } from 'react'
import { DataTable, DropDown, Modal } from 'components'
import { filterOptions } from 'constants/filterOptions'
import { previewWebDataset } from 'constants/tableConstants'
import useSnackbar from 'services/useSnackbar'
import { handleCustomDatasetPayload } from '../utils/PayloadHandlers'
import RequestHandler from '../utils/RequestHandler'
import { pathFor } from 'constants/apiPaths'
import BidstreamWrapper, { fetchBidstreamData } from '../utils/BidstreamWrapper'


function PreviewModal(props) {
    const setMessage = useSnackbar()
    const { payload, geoLocations, setNinaLoader, setModalMode, extras} = props
    const [ filterBy, setFilterBy ] = useState(null)
    const [ isModalOpen, setIsModalOpen ] = useState(false)
    const [ results, setResults ] = useState(null)
    const [ tableBody, setTableBody ] = useState(null)
    const [ bidstream, setBidstream ] = useState(null)
    const [ geos, setGeos ] = useState(null)

    const closeModal = () => {
        setModalMode(null)
        setIsModalOpen(false)
    }

    // const handleFilter = (k, v) => {
    //     const temp = { ...filterBy }

    //     !!v.length ? temp[k] = v : delete temp[k]
    //     setFilterBy(temp)
    // }

    const fetchTableHead = () => {
        const keysToBeRemoved = ["geo", "ad_slots", "avg_cpm", "impressions"]
        return geoLocations?.length
                ? previewWebDataset
                : previewWebDataset.filter(item => !keysToBeRemoved.includes(item.key))
    }

    useEffect(async () => {
        if (tableBody) {
            setIsModalOpen(true)
        } else {
            if (geoLocations?.length) {
                const bidstreamData = await fetchBidstreamData(geoLocations, 28)
                if ("error" in bidstreamData) return setMessage(bidstreamData)
                                
                const { bidstream_domains } = bidstreamData
                payload["domains"] = bidstream_domains
                setBidstream(bidstreamData)
                setGeos(geoLocations)
            } 

            RequestHandler(pathFor.RecommendationEngine, payload, setMessage, setNinaLoader, setModalMode, setResults)
        }
    }, [ tableBody ])

    useEffect(async() => {
        if (results) {
            geoLocations?.length && bidstream 
                ? BidstreamWrapper(bidstream.bidstream_records, bidstream.bidstream_domains, geos, results, setTableBody, () => {}, setMessage)
                : setTableBody(results)
        }
    }, [ results ])


    return (
        <Modal handleClose={ closeModal } isOpen={ isModalOpen } >
            <h5 className='font-weight-bold mb-3 text--primary'>Preview</h5>
            <DataTable tableData={{ tableHead: fetchTableHead(), tableBody }} filterBy={ filterBy } noExport is_custom >
                {/* <div className='d-flex h-100 flex-grow-1'>
                    {
                        geoLocations?.length ? (
                            <DropDown options={ geoLocations } setSelected={(v) => handleFilter("geo", v)} defaultValue="Select geolocation" /> 
                            ) : <></>
                        }
                    {
                        filterOptions["Web"].map(item => (
                            <DropDown options={ item.options } setSelected={(v) => handleFilter(item.key, v)} defaultValue={ `Select ${item.defaultValue}` }  className="ml-3" /> 
                        ))
                    }
                </div> */}
            </DataTable>
        </Modal>
    )
}

export default PreviewModal