import React, { useEffect, useState } from 'react'
import InfoDisplay from './InfoDisplay'
import PageLength from './PageLength'
import Pagination from './Pagination'


function DataTableControllers(props) {
    const { className, setTableBody, records, extensions } = props
    const [ pageLength, setPageLength ] = useState(10)
    const [ currentPage, setCurrentPage ] = useState(1)

    const total = records.length
    const start = total ? pageLength * (currentPage - 1) : 0
    const count = pageLength * currentPage
    const end = !total ? 0 : total < count ? total : count  
    const paginationProps = { total, pageLength, currentPage, setCurrentPage }

    useEffect(() => {
        setTableBody(records.slice(start, end))
    }, [ start, end ])

    return (
        <div className={ className || "mt-3 mb-4" }>
            <div className='d-flex justify-content-between align-items-end'>
                <PageLength pageLength={ pageLength } setPageLength={ setPageLength } />
                { extensions }
            </div>
            { props.children }
            <div className={ `d-flex justify-content-between mt-3` }>
                <InfoDisplay info={ { start, end, total } } />
                <Pagination { ...paginationProps } className="text-right"/>
            </div>
        </div>
    )
}

export default DataTableControllers