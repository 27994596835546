import React, { useEffect, useState } from 'react'


function DomainsTable(props) {
    const { editable, domains, handleSelect, selectedDomains, setSelectedDomains, className, _10rows } = props
    const [ selectedAll, setSelectedAll ] = useState(null)

    useEffect(() => {
        if (selectedAll !== null) {
            selectedAll ? setSelectedDomains(domains) : setSelectedDomains([])
        }
    }, [ selectedAll ])
    
    useEffect(() => {
        if (editable && !selectedDomains.length) {
            setSelectedAll(false)
        }
    }, [ selectedDomains ])

    
    return (
        <div className={ className || 'border mt-3 rounded'}>
            <p className='text--primary bg--gray m-0 px-3 py-2 border-bottom d-flex align-items-center'>
                {
                    editable && domains?.length ? (
                        <div className='custom-checkbox pointer' onClick={() => setSelectedAll(!selectedAll)}>
                            {
                                selectedAll ? (
                                    <div className='custom-checkbox-fill'>
                                    </div>
                                ) : <></>
                            }
                        </div>
                    ) : <></>
                }
                Domain Name
            </p>
            <div className={ `scrollable domains-container-${ _10rows ? "md" : "sm" } px-3 py-2 bg-white` }>
                {
                    domains?.length ? domains.map((domain, idx) => (
                        <div className={ `py-1 d-flex align-items-center ${ idx && "border-top" }` }>
                            {
                                editable ? (
                                    <div className='custom-checkbox pointer' onClick={() => handleSelect(domain)}>
                                        {
                                            selectedDomains.includes(domain) ? (
                                                <div className='custom-checkbox-fill'>
                                                </div>
                                            ) : <></>
                                        }
                                    </div>
                                ) : <></>
                            }
                            { domain }
                        </div>
                    )) : (
                        <div className='h-100 px-2 overflow-hidden'>
                            {
                                [ ...Array(12).keys() ].map((_, idx) => (
                                    <div className={ `border-bottom py-${ idx ? "3" : "2" }` }>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default DomainsTable