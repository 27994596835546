import React from 'react'
import { AltDropDown } from 'components'
import { countryCodes, intentMappings } from 'constants/mappings'
import { capitalise } from 'utils/common'


function DatasetFilters(props) {
    const { features, className } = props

    const featureOptions = {
        geolocation: countryCodes.map(country => ({
                displayText: `${country.countryName} - ${country.alpha3Code}`,
                value: country.alpha3Code
            })),
        language: ["English", "Spanish", "Danish", "Finnish", "Norwegian", "Swedish"],
        sentiment: ["Positive", "Negative"],
        intent: Object.keys(intentMappings),
        freshness: ["Any", "Last day", "Last 7 days", "Last 14 days", "Last 28 days" ]
    }

    return (
        <div className={ className || "row" }>
            {
                Object.entries(features).map(([k, v], idx) => (
                    <div className='col mb-responsive d-flex'>
                        <div className='flex-grow-1'>
                            <AltDropDown
                                className="border py-2 w-100 d-flex justify-content-between align-items-center"
                                label={ capitalise(k) }
                                placeholder={ `Select ${k}${ v.multi ? "s": ""}` }
                                options={ featureOptions[k] }
                                { ...v }
                            />
                        </div>
                        {
                            props.children && (idx === Object.keys(features).length - 1)
                                ? <div className='ml-3'>{ props.children }</div>
                                : <></>
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default DatasetFilters
