import React, { useState } from 'react'
import Request from 'services/Request'
import { pathFor } from 'constants/apiPaths'
import { Btn, ConfirmationBox } from 'components'
import useSnackbar from 'services/useSnackbar'


function DV360Accounts(props) {
    const setMessage = useSnackbar()
    const { existingDV360s, fetchDV360s, organisation_id, childClassName } = props
    const [ delinkLabel, setDelinkLabel ] = useState(null)

    const handleDelink = async (label) => {
        try {
            const { statusCode, jsonResponse } = await Request(pathFor.DeLinkDV360, { label, organisation_id })
            if (statusCode === 200) {
                setMessage(jsonResponse)
                fetchDV360s()
            }
        } catch (err) {
            setMessage({error: "Server error. Try again after sometime."})
        }
    }

    return (
        <>
        {
            existingDV360s && existingDV360s.map(item => (
                <div className={ childClassName }>
                    <div className="w-100 px-4 py-3 border rounded mb-3 d-flex align-items-center justify-content-between">
                        <span className='text--primary'>Name : <span className='ml-2 text--body'>{ item }</span></span>
                        <Btn onClick={() => setDelinkLabel(item)} className="cw-1">Delink</Btn>
                    </div>
                </div>
            ))
        }
        {
            delinkLabel ? (
                <ConfirmationBox 
                    message={ `Are you sure you want to delink ${ delinkLabel }?` }
                    callbackFn={() => handleDelink(delinkLabel)}
                    clearFn={() => setDelinkLabel(null)}
                />
            ) : <></>
        }
        </>
    )
}

export default DV360Accounts