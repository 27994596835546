import React, { useState } from 'react'

function TruncatedText(props) {
    const { value, length } = props
    const [ hovered, setHovered ] = useState(false)

    return (
        <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className="pointer position-relative">
            <span className={ `tool-tip scrollable d-${hovered ? "flex" : "none"}` }>{ value }</span>
            { value.slice(0, length) + "..." }
        </div>
    )
}

export default TruncatedText