import React from 'react'
import useWindowDimensions from 'utils/windowDimensions'


function Draggable(props) {
    const { setPropLocation, className } = props
    const { height } = useWindowDimensions()

    const onDragEnd = event => {
        event.clientY >= height * 0.5 ? setPropLocation("lowerProp") : setPropLocation("upperProp")
    }

    return (
        <div draggable className={ className } onDragEnd={ onDragEnd }>
            { props.children }
        </div>
    )
}

export default Draggable