import { useEffect, useState } from 'react';

const useGoogle = (callbackFn) => {
    const [ googleClient, setGoogleClient ] = useState(null)


    useEffect(() => {
        
        const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID
        const scope = 'https://www.googleapis.com/auth/display-video email openid'

        const initClient = () => {
            const client = window.google.accounts.oauth2.initCodeClient({
                client_id,
                scope,
                ux_mode: 'popup',
                callback: callbackFn
            });

            setGoogleClient(client)
        }

        const script = document.createElement('script');

        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        script.onload = initClient;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };

    }, []);

    return googleClient
};


export { useGoogle }