import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";


function DateSelector(props) {
  const { setDateDelta } = props;
  const [ selected, setSelected ] = useState("Last 7 days");

  const options = {
    0: "Today",
    1: "Last day",
    7: "Last 7 days",
    14: "Last 14 days",
    28: "Last 28 days"
  }


  return (
    <>
      <Dropdown
        onSelect={(k) => {
          setSelected(options[k]);
          setDateDelta(parseInt(k, 10));
        }}
      >
        <Dropdown.Toggle
          variant=""
          className={`date-selector shadow border-0 bg-white d-flex align-items-center justify-content-between text-muted`}
        >
          <div>{selected}</div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="border-0 shadow-lg rounded-lg p-2">
          {
            Object.entries(options).map(([k, v]) => (
              <Dropdown.Item eventKey={ k } className="text-muted rounded pl-2">
                { v }
              </Dropdown.Item>
            ))
          }
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default DateSelector;
