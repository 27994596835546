import React from 'react'
import { Pie } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels';


function PieChart(props) {
    const { chartData, title, legendDirection, dimensions, layout, labelOffset, legendAlignment, colors } = props
    const stats = chartData && chartData.length ? chartData : [{label: "No Data Available", value: 1}]
    const colorSet = colors || ["#5F94FF", "#FF7E7E", "#12e2a3", "#0275d8", "#d72323", "#5cb85c", "#FFB987", "#5bc0de", "#f5eb82", "#9c1de7", "#fd0054" ]
    const bgColors = chartData && chartData.length ? colorSet : ["#ddd"]
    const labels = stats.map(item => item.label)
    const values = stats.map(item => item.value)
    const totalSum = values.reduce((a, b) => a + b, 0)
    const percentage = value => (value * 100) / totalSum
    
    const calculateOffsets = () => {
        let o
        const offsets = []
        let decreaseOffset = 0

        values.map(v => percentage(v) < 5).forEach(i => {
            if (!i) {
                o = 0
            } else {
                o = labelOffset - decreaseOffset
                decreaseOffset = decreaseOffset >= labelOffset ? 0 : decreaseOffset + 30
            }
            offsets.push(o)
        })

        return offsets
    }

    const offsets = calculateOffsets()

    const data = {
        labels,
        datasets: [{
            label: title,
            data: values,
            backgroundColor: bgColors,
            borderWidth: 0
        }]
    }

    const datalabels = {
        display: chartData.length,
        color: offsets.map((o, idx) => o !== 0 ? `#${12 - idx}1${12 - idx}3${12 - idx}5` : "#ffffff"),
        align: "end",
        offset: offsets,
        font: {
          weight: 'bold',
          size: 18
        },
        formatter: v => percentage(v).toFixed(2) + "%",
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        layout,
        plugins: {
            title: {
                display: true,
                text: title,
                align: "start",
                color: "#8494a4",
                font: {
                    weight: "bold",
                    size: 16,
                }
            },
            tooltip: {
                enabled: chartData.length,
            },
            datalabels,
            legend: {
                display: true,
                position: legendDirection,
                align: legendAlignment || "center",
                labels: {
                    boxWidth: 22,
                    fontSize: 20,
                    fontColor: "light-grey",
                },
            }
        }
    }

    return (
        <Pie data={ data } options={ options }  plugins={[ChartDataLabels]} height={ parseFloat(dimensions.height) } width={ parseFloat(dimensions.width) } />
    )
}


export { PieChart }