import React, { useEffect, useState } from 'react'
import Request from 'services/Request'
import useSnackbar from 'services/useSnackbar'
import DatasetFilters from '../common/DatasetFilters'
import PageLayout from '../common/PageLayout'
import SuggestURLS from '../common/SuggestURLS'
import TaxonomyItem from '../misc/TaxonomyItem'


function TaxonomyTargeting(props) {
	const { setNinaLoader } = props
	const setMessage = useSnackbar()
    const [ geoLocations, setGeoLocations ] = useState([])
    const [ languages, setLanguages ] = useState([])
	const [ freshness, setFreshness] = useState(["Any"])
    const [ sentiments, setSentiments ] = useState([])
    const [ intents, setIntents ] = useState([])
	const [ taxonomyList, setTaxonomyList ] = useState([])
    const [ taxonomies, setTaxonomies ] = useState([])
    const [ tableBody, setTableBody ] = useState([])

	const [ geos, setGeos ] = useState(null)
	const [ payload, setPayload ] = useState(null)

	useEffect(async () => {
		try{
            const { statusCode, jsonResponse } = await Request("/fetch-taxonomies")
            if (statusCode === 200) {
                const { data } = jsonResponse
                setTaxonomyList(data)
            } else setMessage(jsonResponse)
        } catch {
            setMessage({error: "Server error. Try again after sometime."})
        }
	}, [])

	const featuresSet = {
		geolocation: {
			selected: geoLocations,
			setSelected: setGeoLocations,
			filter: true,
			multi: true,
			selectedFirst: true
		},
		language: {
			selected: languages,
			setSelected: setLanguages
		},
		intent: {
			selected: intents,
			setSelected: setIntents,
			multi: true
		},
		sentiment: {
			selected: sentiments,
			setSelected: setSentiments,
			multi: true
		},
		freshness: {
			selected: freshness,
			setSelected: setFreshness
		}
	}

	const pageLayoutProps = {
		features: { geoLocations, languages, intents, emotions: sentiments, taxonomies, freshness },
		primaryQuery: { taxonomies },
		tableBody, setTableBody, setNinaLoader,
        geos, setGeos, payload, setPayload
	}

    return (
		<PageLayout { ...pageLayoutProps }>
			<div className='row'>
				<div className='col'>
					<span className='label-text'>
						Taxonomy categories
					</span>
					<div className="scrollable taxonomies-container mt-1 border rounded-lg">
					{
						taxonomyList?.length ? taxonomyList.map((option, idx) => (
							<TaxonomyItem dropDownItem={ option } taxonomies={ taxonomies } setTaxonomies={ setTaxonomies } idx={ idx } />
						)) : <></>
					}
					</div>
				</div>
				<div className='w-50 mt-4 pt-1'>
					<DatasetFilters features={ featuresSet } className="d-flex flex-column" />
					<div className='text-right mr-3'>
						<SuggestURLS { ...pageLayoutProps } />
					</div>
				</div>
			</div>
		</PageLayout>
    )
}

export default TaxonomyTargeting
