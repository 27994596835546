import React, { useEffect, useState } from 'react'


function TextArea(props) {
    const { placeholder, label, onChange, className, errorText, setErrorText, value, rows } = props
    const [ miniLabel, setMiniLabel ] = useState(null)
    
    const handleMiniLabel = value => {
        if (value.length) {
            if (!miniLabel) setMiniLabel(label || placeholder)
        } else {
            setMiniLabel(null)
        }
    }

    const handleChange = event => {
        handleMiniLabel(event.target.value)
        onChange(event)
        setErrorText && setErrorText(null)
    }

    useEffect(() => {
        if (value) handleMiniLabel(value)
    }, [ value ])

    const textAreaProps = { ...props, rows: rows || "5", onChange: handleChange, className: `border-0 rounded-lg w-100 input-box` }

    return (
        <div className={ `position-relative ${ className } ${ errorText?.length && "border-danger" } bg-white border rounded-lg` }>
            {
                miniLabel && <span className='mini-label rounded-pill'>{ miniLabel }</span>
            }
            <textarea { ...textAreaProps }></textarea>
            {
                errorText && <div className="text-danger text-right input-error rounded-pill" data-testid="new-password-error">
                    <i>{ errorText }</i>
                </div>
            }
        </div>
    )
}

export default TextArea
