import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Request from 'services/Request'
import useSnackbar from 'services/useSnackbar'


function ActivateAccount() {
    const setMessage = useSnackbar()
    const history = useHistory()
    const [ tokenError, setTokenError ] = useState(null)

    useEffect(async () => {
        const token = history?.location.search?.split("token=")[1]
        if (!token) return setTokenError("Token unavailable.")
        try {
            const headers = { 'Authorization': `Bearer ${token}` }
            const { statusCode, jsonResponse } = await Request("/activate-account", {}, headers)
            
            if (statusCode === 303) history.push(`/accounts${jsonResponse.redirect_to}`)
            else {
                const tokenErrorMessage = jsonResponse.errors?.headers?.Authorization
                if (tokenErrorMessage) {
                    setTokenError(tokenErrorMessage[0])
                } else if ("error" in jsonResponse) {
                    setMessage(jsonResponse)
                } else {
                    setMessage({error: "Invalid token."})
                }
            }
        } catch (err) {
            setMessage({error: "Server error. Try again after sometime."})
        }
    }, [])


    return (
        <>
            {
                tokenError && (
                    <p className='label-text text-danger text-center pt-5 pl-2'>{ tokenError }</p>
                )
            }
        </>
    )
}

export default ActivateAccount