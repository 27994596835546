import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Close from 'assets/icons/misc/Close.svg'


const msgTypes = {
    error: {
        icon: faTimesCircle,
        color: "red",
    },
    success: {
        icon: faCheckCircle,
        color: "green"
    },
    info: {
        icon: faInfoCircle,
        color: "blue"
    }
}

function Snackbar(props) {
    const { item, closeSnackbar, className } = props
    const { timeStamp, closed } = item
    const msgKey = Object.keys(item).filter(item => Object.keys(msgTypes).includes(item))[0]
    const msgType =  msgTypes[msgKey]
    const { color, icon } = msgType
    const [ fadeOut, setFadeOut ] = useState(false)

    const closeSelf = () => closeSnackbar(item)
    useEffect(() => {
        const currentTime = + (new Date())
        setTimeout(() => setFadeOut(true), timeStamp - currentTime - 2000)
        setTimeout(closeSelf, timeStamp - currentTime)
    }, [])


    return (
        <>{
            closed || timeStamp - (+ (new Date())) <= 2000 ? <></> : (
                <div className={ `snackbar snackbar-${ color } ${ className } ${ fadeOut && "fade-out" } shadow rounded p-3 d-flex justify-content-between align-items-center` }>
                    <span className="d-flex align-items-center">
                        <FontAwesomeIcon icon={ icon } className={ `mr-2 text--${ color }` } />
                        <span className="px-1">{ item[msgKey] }</span>
                    </span>
                    <strong className="cross pointer" onClick={ closeSelf }>
                        <img src={ Close } alt="close" />
                    </strong>
                </div>
            )
        }</>
    )
}

export default Snackbar