import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';


function Routes(props) {
    const { pages } = props
    const match = useRouteMatch()
    const rootPath = match.path

    let routes = []
    const createRoute = (item, parentPath) => {
        const { component: Component, path } = item
        return (
            <Route path={ `${parentPath}${path}` }>
                <Component { ...props } />
            </Route>
        )
    }

    pages.forEach(page => {
        if (page.menu) {
            page.menu.forEach(pageMenu => {
                if (pageMenu.menu) {
                    pageMenu.menu.forEach(item => {
                        routes.push(createRoute(item, `${rootPath}${page.path}${pageMenu.path}`))
                    })
                    routes.push(<Redirect from={ `${rootPath}${page.path}${pageMenu.path}` } to={ `${rootPath}${page.path}${pageMenu.path}${pageMenu.menu[0].path}` } />)
                } else {
                    routes.push(createRoute(pageMenu, `${rootPath}${page.path}`))
                }
            })
            routes.push(<Redirect from={ `${rootPath}${page.path}` } to={ `${rootPath}${page.path}${page.menu[0].path}` } />)
        } else {
            routes.push(createRoute(page, rootPath))
        }
    })


    return (
        <Switch>
            { routes }
            <Redirect from={ rootPath } to={ `${rootPath}${pages[0].path}` } />
        </Switch>
    )

}

export default Routes
