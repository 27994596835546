import Request from "./Request"


const analyze = async (payload) => {
    const payloadKey = Object.keys(payload)[0]
    try {
        const { statusCode, jsonResponse } = await Request(`/v2/analyze/${payloadKey}`, payload)

        if (statusCode === 400) return {error: `Failed to analyze ${payloadKey}. Please try again.`}
        if ("info" in jsonResponse) return {error: jsonResponse.info}

        if ("taxonomies" in jsonResponse) {
            jsonResponse.taxonomies = Object
                .entries(jsonResponse.taxonomies)
                .sort(([,x], [,y]) => y - x)
                .map((item, idx) => ({ taxonomy: item[0], rank: idx + 1}))
        }

        return jsonResponse

    } catch (error) {
        return {error: "Server error. Try again after sometime."}
    }
}

export { analyze }