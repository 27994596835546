import React, { useEffect, useState } from 'react'
import Request from 'services/Request'
import { pathFor } from 'constants/apiPaths'
import DV360Accounts from '../misc/DV360Accounts'
import useSnackbar from 'services/useSnackbar'


function DSPLinkedAccounts(props) {
    const setMessage = useSnackbar()
    const { organisation_id } = props
    const [ existingDV360s, setExistingDV360s ] = useState([])
    
    const fetchDV360s = async () => {
        try {
            const { statusCode, jsonResponse } = await Request(pathFor.FetchDV360s, { organisation_id })
            if (statusCode === 200) setExistingDV360s(jsonResponse.data.map(item => item.label))
        } catch (err) {
            setMessage({error: "Server error. Try again after sometime."})
        }
    }

    useEffect(() => fetchDV360s(), [])

    return (
        <div className='mt-4'>
            {
                existingDV360s?.length ? (<>
                    <p className="label-text pt-2">DSP LINKED ACCOUNTS</p>
                    <div className='row'>
                        <DV360Accounts 
                            { ...{ existingDV360s, fetchDV360s, organisation_id } } 
                            childClassName="col-4"
                        />
                    </div>
                </>) : (<></>)
            }
        </div>
    )
}

export default DSPLinkedAccounts