import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import UsersManagement from './UsersManagement';
import AddUser from './AddUser';
import EditUser from './EditUser';


function Routes(props) {
    const match  = useRouteMatch()
    const components = {
        "": UsersManagement,
        "/add-new-user": AddUser,
        "/edit-user/:id": EditUser
    }

    const routes = Object.entries(components).map(([path, Component]) => (
        <Route exact path={ `${ match.path }${ path }` }>
            <Component { ...props } />
        </Route>
    ))

    return (
        <div> 
            <Switch>
                { routes }
                <Redirect to={ match.path } />
            </Switch>
        </div>
  )
}

export default Routes