const pathFor = {
    InAppUrl: "/recommendation-engine/in-app",
    RecommendationEngine: "/recommendation-engine/web",
    URL: "/keywords-generator/url",
    Description: "/keywords-generator/description",
    AccountDetails: "/account-details",
    FetchStoredDomains: "/fetch-stored-domains",
    UpdateStoredDomains: "/update-stored-domains",
    DomainsData: "/domains-data",
    LinkDV360: "/link-dv360",
    DeLinkDV360: "/delink-dv360",
    FetchDV360s: "/fetch-dv360-accounts",
    Publish: "/publish-to-dv360",
    ActivateAccount: "/activate-account",
    Members: "/members",
    AddMember: "/add-member",
    EditMember: "/edit-member",
    UsersStats: "/users-stats",
    DatasetActivities: "/dataset-activities",
    UsersActivities: "/users-activities"
}

export { pathFor }
