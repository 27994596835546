const filterData = (data, filters) => {
    
    if ("fields" in filters) {
        return data.filter(
            item => filters.fields.some(
                f => item[f] && item[f].toLowerCase().includes(
                    filters.key.toLowerCase()
                )
            )
        )
    } else {
        let temp = [...data];

        Object.entries(filters).forEach(([k, v]) => {
            temp = temp.filter(item => item[k] && (k === "geo" ? item[k].includes(v) : item[k].toLowerCase() === v.toLowerCase()))
        })

        return temp
    }
}

export default filterData