import React, { useEffect, useState } from 'react'


function InputBox(props) {
    const { placeholder, label, onChange, className, errorText, setErrorText, value, readOnly } = props
    const [ miniLabel, setMiniLabel ] = useState(null)
    
    const handleMiniLabel = value => {
        if (value.length) {
            if (!miniLabel) setMiniLabel(label || placeholder)
        } else {
            setMiniLabel(null)
        }
    }

    const handleChange = event => {
        handleMiniLabel(event.target.value)
        onChange(event)
        setErrorText && setErrorText(null)
    }

    useEffect(() => {
        if (value) handleMiniLabel(value)
    }, [ value ])

    const inputProps = { ...props, onChange: handleChange, className: `${ className } ${ readOnly ? "readOnly-gradient text-muted" : "bg-white" } border ${ errorText?.length && "border-danger" } w-100 rounded input-box` }
    
    return (
        <div className='position-relative'>
            {
                miniLabel && <span className='mini-label rounded-pill'>{ miniLabel }</span>
            }
            <input { ...inputProps } />
            {
                errorText && <div className="text-danger text-right input-error rounded-pill" data-testid="new-password-error">
                    <i>{ errorText }</i>
                </div>
            }
        </div>
    )
}

export default InputBox