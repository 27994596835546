import React, { useEffect, useState } from "react";
import DV360Targets from './DV360Targets'
import Request from 'services/Request'
import { pathFor } from 'constants/apiPaths'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faPenToSquare, faTrashCan }  from '@fortawesome/free-regular-svg-icons'
import { useGoogle } from "services/useGoogle";
import DV360Accounts from "../../misc/DV360Accounts";
import { Prompt } from "react-router-dom";
import useSnackbar from "services/useSnackbar";
import { Btn } from "components";


function PublishToDV360(props) {
    const setMessage = useSnackbar()
    const { query, target_size, callbackFn, setNinaLoader } = props
    const currentOrg = sessionStorage.getItem("currentOrg")
    const organisation_id = currentOrg && JSON.parse(currentOrg)["organisation_id"]
    const [ existingDV360s, setExistingDV360s ] = useState([])
    const [ targets, setTargets ] = useState([])
    const [ addDV360, setAddDV360 ] = useState(false)
    const [ newDV360, setNewDV360 ] = useState("")
    const [ error, setError ] = useState(null)
    const [ addTarget, setAddTarget ] = useState(false)
    const [ editTargetKey, setEditTargetKey ] = useState(null)
    const [ authCredentials, setAuthCredentials ] = useState(null)
    const [ published, setPublished ] = useState(false)
    const [ fetchedAccounts, setFetchedAccounts ] = useState(null)
    const googleClient = useGoogle(setAuthCredentials)
    const DV360TargetsProps = { existingDV360s, targets, editTargetKey, published, setTargets, setAddTarget, setEditTargetKey }

    const fetchDV360s = async () => {
        try {
            const { statusCode, jsonResponse } = await Request(pathFor.FetchDV360s, { organisation_id })
            if (statusCode === 200) {
                const { data } = jsonResponse
                setFetchedAccounts(data)
                setExistingDV360s(data.map(item => item.label))
            }
        } catch (err) {
            setMessage({error: "Server error. Try again after sometime."})
        }
    }

    useEffect(async () => {
        if (authCredentials) {
            if ("error" in authCredentials) return setMessage({error: "Authentication incomplete."})
            try {
                const { statusCode, jsonResponse } = await Request(pathFor.LinkDV360, { ...authCredentials, label: newDV360, organisation_id})
                if (statusCode === 200) {
                    setAddDV360(false)
                }
                setMessage(jsonResponse)
            } catch (err) {
                setMessage({error: "Server error. Try again after sometime."})
            }
        }
        fetchDV360s()
    }, [authCredentials])

    const handleLink = async () => {
        setError(null)
        if (existingDV360s.includes(newDV360)) return setError("Label name already exists.")
        googleClient.requestCode()
    }

    const constructPublishPayload = () => {
        const tempTargets = []
        targets.forEach(target => {
            const { accountId, userType, userId, channelId, channelName } = target
            const tempTarget = { "account_label": accountId.trim(), "account_id": fetchedAccounts.filter(item => item["label"] === accountId)[0]["_id"].trim() }
            channelId ? tempTarget["channel_id"] = channelId.trim() : tempTarget["channel_name"] = channelName.trim()
            tempTarget[`${userType.toLowerCase()}_id`] = userId.trim()
            tempTargets.push(tempTarget)
        })

        return { targets: tempTargets, ...query, target_size }
    }

    const publish = async () => {
        setMessage()
        if (!target_size) return setMessage({error: "\"Expected URLs\" is a required field."})

        setNinaLoader(true)
        try {
            const { statusCode, jsonResponse } = await Request(pathFor.Publish, constructPublishPayload())
            if (statusCode === 200) {
                setTimeout(() => {
                    setMessage(jsonResponse)
                    setPublished(true)
                    setNinaLoader(false)
                    callbackFn()
                }, 2000)
            } else {
                setMessage(jsonResponse)
                setNinaLoader(false)
            }
        } catch (err) {
            setMessage({error: "Server error. Try again after sometime."})
            setNinaLoader(false)
        }
    }

    const clear = () => {
        setTargets([])
        setPublished(false)
        setMessage()
    }

    useEffect(async () => {
        setError(null)
    }, [newDV360, addDV360, addTarget])


    useEffect(() => {
        if (!existingDV360s.length) setTargets([])
    }, [existingDV360s])

    return (
        <div className="mt-5">
            <div className='row'>
                <div className='col-6 pr-5'>
                    <DV360Accounts { ...{ existingDV360s, fetchDV360s, organisation_id } } />
                    {
                        addDV360 && (
                            <div className='w-100 px-4 py-3 border rounded mb-3 d-flex align-items-center'>
                                <span className='text-muted mr-2'>Name</span>
                                <div className='flex-grow-1 my-2 pr-4'>
                                    <input className='border--light rounded px-3 py-1 ml-2 w-100' onChange={e => setNewDV360(e.target.value)}/>
                                    {
                                        error && (
                                            <span className='text-danger position-absolute ml-4'>{error}</span>
                                        )
                                    }
                                </div>
                                <Btn className='cw-1' onClick={ handleLink } disabled={ !newDV360.length }>&nbsp; Link&nbsp;&nbsp;</Btn>
                            </div>
                        )
                    }
                    <Btn className='px-4 mt-2' onClick={() => setAddDV360(!addDV360)}>
                        { addDV360 ? "Cancel" : (
                            <>
                                <FontAwesomeIcon icon={ faPlus } size="1x" className='mr-2'/>
                                DV360 Account
                            </>
                        ) }
                    </Btn>
                </div>
                <div className='col-6 border-left pl-5'>
                    {
                        targets && targets.map((item, idx) => (
                            <>{
                                editTargetKey && parseInt(editTargetKey) === idx ? (
                                    <DV360Targets { ...DV360TargetsProps } />
                                ) : (
                                    <div className={ `row border rounded p-3 mx-auto mb-4 ${ published && "bg--secondary"}` } data-testid={ `target-${idx}` } >
                                        <div className='col-3 text-center py-1'>
                                            <span className='label-text d-block'>Account ID</span>
                                            <span>{ item.accountId }</span>
                                        </div>
                                        <div className='col-3 border-left text-center py-1'>
                                            <span className='label-text d-block'>{ item.userType } ID</span>
                                            <span>{ item.userId }</span>
                                        </div>
                                        <div className='col-3 border-left text-center py-1'>
                                            <span className='label-text d-block'>Channel</span>
                                            <span>{ item.channelId || item.channelName }</span>
                                        </div>
                                        <div className='col-3 d-flex justify-content-center align-items-center'>
                                            <FontAwesomeIcon icon={ faPenToSquare } size="lg" className={ `text-${ published ? "-body" : "-primary pointer"}` } onClick={() => !published && setEditTargetKey(`${idx}`)}/>
                                            <FontAwesomeIcon icon={ faTrashCan } size="lg" className={ `text--${ published ? "body" : "red pointer"} ml-4` } onClick={() => !published && setTargets(targets.filter((_, idy) => idy != idx)) }/>
                                        </div>
                                    </div>
                                )
                            }</>
                        ))
                    }
                    {
                        addTarget && !editTargetKey && (<DV360Targets { ...DV360TargetsProps } />)
                    }
                    <div className='d-flex justify-content-between'>
                        <Btn className='px-4' onClick={() => setAddTarget(!addTarget)} disabled={ !existingDV360s.length || editTargetKey || published }>
                            { addTarget ? "Cancel" : (
                                <>
                                    <FontAwesomeIcon icon={ faPlus } size="1x" className='mr-2'/>
                                    Target Channel
                                </>
                            ) }
                        </Btn>
                        {
                            targets && targets.length ? (
                                <Btn className='cw-1' onClick={ published ? clear : publish } disabled={ editTargetKey || addTarget }>
                                   { published ? "Clear" : "Publish" }
                                </Btn>
                            ) : (<></>)
                        }
                    </div>
                </div>
            </div>
            <Prompt message="Are you sure you want to leave?"/>
        </div>
    )
}

export default PublishToDV360

