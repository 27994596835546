import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import TaxonomyItem from '../../misc/TaxonomyItem'


function TaxonomyDropdown(props) {
    const { taxonomyList, taxonomies, setTaxonomies } = props
    const [ menuOptions, setMenuOptions ] = useState([])
    // const [ filterText, setFilterText ] = useState("")

    // const setTaxonomies = value => {
    //     setSelected(selected?.includes(value) ? selected.filter(i => i !== value) : [ ...selected, value ])
    // }

    // useEffect(() => {
    //     setMenuOptions(taxonomyList?.filter(o => {
    //         let opt = typeof o === "object" ? o.displayText + " " + o.hoverText : o
    //         return opt.toLowerCase().includes(filterText.toLowerCase())
    //     }) || [])
    // }, [ taxonomyList, filterText ])

    useEffect(() => {
        if (taxonomyList) {
            setMenuOptions(taxonomyList)
        }
    }, [ taxonomyList ])

    const [ miniLabel, setMiniLabel ] = useState(null)
    
    useEffect(() => {
        if (taxonomies) {
            setMiniLabel(taxonomies?.length ? "Taxonomy categories" : null)
        }
    }, [ taxonomies ])


    return (
        <div className='mb-responsive'>
            <Dropdown>
                <Dropdown.Toggle variant="white" id="dropdown-basic" className="border w-100 d-flex justify-content-between align-items-center position-relative">
                    {
                        miniLabel && <span className='dropdown-mini-label rounded-pill'>{ miniLabel }</span>
                    }
                    <div className="pr-4 d-inline">
                        { !taxonomies.length ? <span className='text--secondary'>Select taxonomy categories</span> : taxonomies?.length === 1 ? taxonomies[0] : "Multiple selected" }
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className={ `${menuOptions ? "w-75" : "d-none"} text--secondary border-0 w-100 shadow-lg` }>
                    {/* <div className="p-2">
                        <input className="mb-2 w-100 border rounded py-1 px-2" placeholder="Type to filter..." onChange={e => setFilterText(e.target.value)} />
                        <Dropdown.Divider />
                    </div> */}
                    <div className="scrollable menu">
                    {
                        menuOptions?.length && menuOptions.map((option, idx) => (
                            <TaxonomyItem dropDownItem={ option } taxonomies={ taxonomies } setTaxonomies={ setTaxonomies } idx={ idx } />
                        ))
                    }
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default TaxonomyDropdown