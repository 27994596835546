import { constraints } from "constants/messages"
import { validateKeywords } from "utils/validations"
import { intentMappings } from "constants/mappings"


const handleIntentValues = intents => {
    let intentValues = []

    intents?.forEach(item => {
        intentValues = [ ...intentValues, ...intentMappings[item] ]
    })

    return intentValues
}

const handleCustomDatasetPayload = (features, extras, errorCallbackFns) => {
    const { keywords, taxonomies, intents } = features

    const geoLocations = features.geoLocations?.map(item => item?.split("-").pop().trim())
    delete features.geoLocations

    const keywordsList = keywords?.toLowerCase().split(",").map(item => item.trim()).filter(item => item?.length)
    const payload = { ...(Object.fromEntries(Object.entries(features).filter(([_, v]) => v?.length))) }
    payload["intents"] = handleIntentValues(features.intents)

    if (!keywords?.length && !taxonomies?.length) return {error: "Either keywords or taxonomies required."}
    if (keywords?.trim().length) {
        // if (keywordsList.length < 8) return {error: "At least 8 keywords required."}
        if (keywordsList.length > 15) return {error: "Maximum 15 keywords allowed."}
        if (keywordsList.some(item => item.length < 2)) return {error: "Each keyword must be of at least 2 characters."}
    }

    return [{
        ...payload,
        keywords: keywordsList,
        ...(extras || {})
    }, geoLocations]
}

const keywords_related_features = [ "keywords", "keywords_blacklist", "keywords_whitelist", "keywords_all_inclusive" ]

const handleTargetingPayload = (features, extras, errorCallbackFns) => {

    const geoLocations = features.geoLocations?.map(item => item?.split("-").pop().trim())
    delete features.geoLocations
    
    const allFeatures = { ...features, ...extras } 
    const payload = { ...(Object.fromEntries(Object.entries(allFeatures).filter(([_, v]) => v?.length))) }
    payload["intents"] = handleIntentValues(allFeatures.intents)
    
    keywords_related_features.forEach(key => {
        const keywords = allFeatures[key]?.toLowerCase()

        if (keywords) {
            payload[key] = validateKeywords({ keywords, ...constraints[key] }, (error => {
                payload["error"] = "Input validation error"
                return errorCallbackFns[key](error)
            }))
        }        
    })
    
    return [payload, geoLocations]
}

export { handleCustomDatasetPayload, handleTargetingPayload, keywords_related_features }