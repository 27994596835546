import Radios from 'components/Radios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'


function SidebarSubMenu(props) {
    const { menu, parentPath, displaySignal, highlightParent, setTitle } = props
    const history = useHistory()
    const [ selected, setSelected ] = useState(menu[0])
    const [ hoveredItem, setHoveredItem ] = useState("")
    const [ isHovered, setIsHovered ] = useState(false)
    const [ display, setDisplay ] = useState(null)
    const [ dismiss, setDismiss ] = useState(false)
    const { path: childPath, menu: subMenu } = menu.filter(item => item.key === selected.key)[0]
    const radioProps = { items: menu.map(i => i.text), selected: selected.text, setSelected, className: "d-flex justify-content-between px-1", disabledItems: ["In-app", "CTV"] }
    const displayMenu = isHovered || display

    useEffect(() => {
        setDismiss(false)
        highlightParent(true)
        setTimeout(() => setDisplay(displaySignal), 300)
    }, [ displaySignal ])

    useEffect(() => {
        !displayMenu && highlightParent(false)
    }, [ displayMenu ])


    return (
        <div className={ `sidebar-sub-menu ${ (displayMenu && !dismiss) && "menu-display" } p-3 rounded-lg shadow` }
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Radios { ...radioProps } />
            {
                subMenu ? (
                    <div className='mt-3 text-muted'>
                        {
                            subMenu.map(item => (
                                <div className={ `p-2 pointer rounded ${ hoveredItem === item.text && "bg--grey text--primary" }` } 
                                    onClick={() => {
                                        setTitle(item.text)
                                        setDismiss(true)
                                        history.push(`${parentPath}${childPath}${item.path}`)
                                    }}
                                    onMouseEnter={() => setHoveredItem(item.text)}
                                    onMouseLeave={() => setHoveredItem("")}
                                >
                                    { item.text.replace("Targeting", "") }
                                </div>
                            ))
                        }
                    </div>
                ) : <></>
            }
        </div>
    )
}

export default SidebarSubMenu