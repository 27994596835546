import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useSnackbar from 'services/useSnackbar'
import Request from '../../services/Request'
import KebabIcon from './assets/KebabIcon.svg'


function MoreActions(props) {
    const setMessage = useSnackbar()
    const { actions, row } = props
    const match  = useRouteMatch()
    const history = useHistory()

    const handleClick = async (path) => {
        try {
            const { jsonResponse } = await Request(`${path}/${row['_id']}`)
            setMessage(jsonResponse)
        } catch (error) {
            setMessage({'error': "Server error. Try again after sometime."})
        }
    }

    return (
        <div className='more-icon pointer'>
            <img src={ KebabIcon } />
            <div className='more-menu-container'>
                <div className='more-menu shadow rounded-lg text-muted'>
                    {
                        actions.map(action => (
                            !action.noDisplayIf ? (
                                <div
                                className="px-3 py-2 rounded more-menu-item" 
                                onClick={() => history.push(`${match.path}${action.path}/${row['_id']}`)}
                                >
                                    { action.text }
                                </div>
                            ) : !(action.noDisplayIf.includes(row[action.dependentOn])) && (
                                <div 
                                className="px-3 py-2 rounded more-menu-item"
                                onClick={() => handleClick(action.path)} 
                                >
                                    { action.text }
                                </div>
                            ) 
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default MoreActions
