import { AltDropDown, Btn, DataTable } from 'components'
import { previewWebDataset } from 'constants/tableConstants'
import React, { useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import useSnackbar from 'services/useSnackbar'
import BidstreamWrapper, { fetchBidstreamData } from '../utils/BidstreamWrapper'
import { keywords_related_features } from '../utils/PayloadHandlers'
import DownloadModal from './DownloadModal'
import PublishModal from './PublishModal'
import './styles.css'


function PageLayout(props) {
    const setMessage = useSnackbar()
    const { features, misc, primaryQuery, tableBody, setTableBody, setNinaLoader, btnClassName, geos, payload } = props
    const [ analyticsPeriod, setAnalyticsPeriod ] = useState(["Last 28 days"])
    const [ modalMode, setModalMode ] = useState("")
    const [ tBody, setTBody ] = useState([])

    const currentOrg = sessionStorage.getItem("currentOrg")
    const organisation_id = currentOrg && JSON.parse(currentOrg)["organisation_id"]
    const primary_query = primaryQuery ? Object.keys(primaryQuery)[0] : "custom_dataset"

    const allFeatures = { ...features, ...(misc || {}), organisation_id, primary_query }

    const doNotLeave = (
        [
            "geoLocations", "intents", "emotions", "languages", "taxonomies", ...keywords_related_features
        ].some(i => allFeatures[i]?.length) || Object.values(primaryQuery)[0]?.length
    )

    const handleReload = event => {
        if (doNotLeave) {
            event.preventDefault()
            event.returnValue = "."
            return "."
        }
    }

    useEffect(() => {
        window.addEventListener("beforeunload", handleReload)
        return () => window.removeEventListener("beforeunload", handleReload)
    }, [ doNotLeave ])

    const buttons =  [
        { text: "Push to DSP" },
        { text: "Download", className: "ml-4" }
    ]

    const ModalComponent = {
        "Push to DSP": PublishModal,
        Download: DownloadModal
    }[modalMode]

    const modalProps = { payload, features: allFeatures, tableUrls: tableBody?.map(i => i.url), setNinaLoader, setModalMode, organisation_id }

    const analyticsPeriodOptions = {
        "Last day": 1,
        "Last 7 days": 7,
        "Last 14 days": 14,
        "Last 28 days": 28
    }

    useEffect(async () => {
        if (analyticsPeriod?.length && (tableBody?.length || tBody?.length)) {
            const bidstreamData = await fetchBidstreamData(geos, analyticsPeriodOptions[analyticsPeriod[0]])
            const { bidstream_records, bidstream_domains } = bidstreamData

            BidstreamWrapper(bidstream_records, bidstream_domains, geos, tableBody?.length ? tableBody : tBody, setTableBody, setTBody, setMessage)
        }
    }, [ analyticsPeriod ])


    return (
        <div className='page-layout scrollable'>
            { props.children }
            <div>
                <DataTable tableData={{ tableHead: previewWebDataset, tableBody }} is_custom>
                    <AltDropDown
                        className="border py-2 d-flex cw-3 justify-content-between align-items-center mt-4"
                        label="Analytics period"
                        placeholder="Analytics period"
                        options={ Object.keys(analyticsPeriodOptions) }
                        setSelected={ setAnalyticsPeriod }
                        selected={ analyticsPeriod }
                    />
                </DataTable>
                <div className='text-right mt-4'>
                    {
                        buttons.map(item => (
                            <Btn
                                className={ `${ item.className } ${ btnClassName || "cw-2" }`  }
                                onClick={() => setModalMode(item.text)}
                                disabled={ !tableBody?.length }
                            >
                                { item.text }
                            </Btn>
                        ))
                    }
                </div>
            </div>
            { ModalComponent && <ModalComponent { ...modalProps } /> }
            <Prompt
                when={ doNotLeave }
                message="Are you sure you want to leave?"
            />
        </div>
    )
}

export default PageLayout
