import { Btn, InputBox, Table } from 'components'
import { taxonomiesTable } from 'constants/tableConstants'
import React, { useState } from 'react'
import { analyze } from 'services/Analyze'
import useSnackbar from 'services/useSnackbar'
import { validateInput } from 'utils/validations'
import DatasetFilters from '../common/DatasetFilters'
import PageLayout from '../common/PageLayout'
import SuggestURLS from '../common/SuggestURLS'


function URLTargeting(props) {
	const { setNinaLoader } = props
	const setMessage = useSnackbar()
	const [ geoLocations, setGeoLocations ] = useState([])
    const [ languages, setLanguages ] = useState([])
	const [ freshness, setFreshness] = useState(["Any"])
    const [ sentiments, setSentiments ] = useState([])
    const [ intents, setIntents ] = useState([])
	const [ safeFromKeywords, setSafeFromKeywords ] = useState("")
	const [ url, setUrl ] = useState("")
	const [ taxonomyTableBody, setTaxonomyTableBody ] = useState([])
	const [ errorText, setErrorText ] = useState(null)
	const [ sfkErrorText, setSfkErrorText ] = useState("")
    const [ tableBody, setTableBody ] = useState([])

	const [ geos, setGeos ] = useState(null)
	const [ payload, setPayload ] = useState(null)

	const handleSubmit = async (event) => {
		event.preventDefault();

        const error = validateInput({ url })
        if (error) setErrorText(error)
        else {
			setNinaLoader("Analyzing")

            const response = await analyze({ url })

            if ("error" in response) setMessage(response)
            else setTaxonomyTableBody(response.taxonomies)

			setTimeout(() => setNinaLoader(false), 300)
        }
	}

	const featuresSet1 = {
		geolocation: {
			selected: geoLocations,
			setSelected: setGeoLocations,
			filter: true,
			multi: true,
			selectedFirst: true
		},
		language: {
			selected: languages,
			setSelected: setLanguages
		}
	}

	const featuresSet2 = {
		intent: {
			selected: intents,
			setSelected: setIntents,
			multi: true
		},
		sentiment: {
			selected: sentiments,
			setSelected: setSentiments,
			multi: true
		},
		freshness: {
			selected: freshness,
			setSelected: setFreshness
		}
	}

	const inputBoxProps = { label: "URL", placeholder: "Enter URL", onChange: (e => setUrl(e.target.value.trim())), errorText, setErrorText }
	const pageLayoutProps = {
		features: { geoLocations, languages, intents, emotions: sentiments, taxonomies: taxonomyTableBody.map(item => item.taxonomy), freshness },
		misc: { keywords_blacklist: safeFromKeywords },
		primaryQuery: { url },
		tableBody, setTableBody, setNinaLoader,
		errorCallbackFns: { keywords_blacklist: setSfkErrorText },
        geos, setGeos, payload, setPayload
	}

  	return (
        <PageLayout { ...pageLayoutProps }>
			<DatasetFilters features={ featuresSet1 } />
			<div className="d-flex mb-responsive align-items-center">
				<div className='flex-grow-1'>
					<InputBox { ...inputBoxProps } />
				</div>
				<Btn className="ml-3 cw-2" disabled={ !url?.length || errorText?.length } onClick={ handleSubmit }>Analyze URL</Btn>
			</div>
			<div className='row'>
				<div className='col'>
					<Table tableData={{ tableHead: taxonomiesTable, tableBody: taxonomyTableBody }} className="table table-bordered" />
				</div>
				<div className='w-50'>
					<DatasetFilters features={ featuresSet2 } className="d-flex flex-column" />
					<div className='px-3 mb-responsive'>
						<InputBox
							placeholder="None of these keywords" onChange={e => setSafeFromKeywords(e.target.value)}
							errorText={ sfkErrorText } setErrorText={ setSfkErrorText }
						/>
					</div>
					<div className='text-right mx-3'>
						<SuggestURLS { ...pageLayoutProps } />
					</div>
				</div>
			</div>
        </PageLayout>
    )
}

export default URLTargeting
