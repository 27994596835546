import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Request from 'services/Request'
import { Btn, InputBox } from 'components'
import useSnackbar from 'services/useSnackbar'
import validatePassword from './utils/validatePassword'


function Login() {
    const setMessage = useSnackbar()
    const history = useHistory()
    const [ email, setEmail] = useState("")
    const [ password, setPassword ] = useState("")
    const [ emailError, setEmailError ] = useState("")
    const [ passwordError, setPasswordError ] = useState("")
    const ninaToken = localStorage.getItem("ninaData")
    const disabled = !email.trim() || !password.trim() || !!emailError || emailError?.length || passwordError?.length
    const token = history?.location.search?.split("token=")[1]

    if (ninaToken) {
        Request("/revoke-token")
        localStorage.clear()
        sessionStorage.clear()
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        const passwordValidation = validatePassword(password, true)
        setPasswordError(passwordValidation)

        if (passwordValidation) return
        try {
            const { statusCode, jsonResponse } = await Request("/login", { email, password, token })
            if (statusCode === 200) history.push("/dashboard")
            if (statusCode === 400) {
                const { errors } = jsonResponse
                if ("json" in errors) {
                    const { email } = errors.json
                    email ? setEmailError("Invalid email.") : setMessage({error: "Invalid input."})
                } else {
                    setMessage({error: "Something went wrong."})
                }
            }
            else setMessage(jsonResponse)
        } catch (error) {
            setMessage({error: "Server error. Try again after sometime."})
        }
    }

    useEffect(() => {
        setEmailError("")
        // eslint-disable-next-line
    }, [ password, email ])


    return (
        <div>
            <form className="mt-4" onSubmit={ handleSubmit }>
                <div className='pb-3'>
                    <InputBox 
                        type="email" 
                        id="email" 
                        placeholder="Email"
                        onChange={e =>  setEmail(e.target.value.trim())}
                        errorText={ emailError }
                    />
                </div>
                <div className="my-3">
                    <InputBox
                        type="password" 
                        id="password" 
                        placeholder="Password"    
                        onChange={e => setPassword(e.target.value.trim())}
                        errorText={ passwordError }
                    />
                </div>
                <a href="/accounts/reset-password" className='pl-1'>Forgot password?</a>
                <div className="d-flex justify-content-center mt-4 pt-2">
                    <Btn type="submit" disabled={ disabled }>Login</Btn>
                </div>
            </form>
        </div>
    )
}

export default Login
