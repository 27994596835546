import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { capitalise } from 'utils/common'


function OrganisationSelectionDropdown(props) {
    const { currentOrg, setCurrentOrg, organisations, propLocation, setSelected, noLabel } = props
 

    return (
        <Dropdown onSelect={val => setCurrentOrg(organisations.filter(o => o.organisation_id === val)[0])} drop={ propLocation === "lowerProp" ? "up" : "down"}>
            { noLabel ? <></> : <span className='mini-label rounded-pill'>Accounts</span> }
            <Dropdown.Toggle variant="white" id="dropdown-basic" className="bg-white border--light w-100 d-flex align-items-center justify-content-between py-2">
                { currentOrg?.organisation_name || "Select" }
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-100 p-2 border-0 shadow">
                {
                    organisations?.map(item => (
                        <Dropdown.Item eventKey={ item.organisation_id } className="p-2 rounded" onClick={() => setSelected && setSelected(item)} >{ capitalise(item.organisation_name) }</Dropdown.Item>
                    ))
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default OrganisationSelectionDropdown