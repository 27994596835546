import React from 'react';
import Logo from 'assets/images/logo/Logo.svg';
import LogoText from 'assets/images/logo/LogoText.svg';


function Page(props) {
    const { component: Component } = props

    return (
        <div className="page d-flex justify-content-center align-items-center">
            <div className="auth-card">
                <div className="d-flex justify-content-center pb-3">
                    <img src={ Logo } alt="logo" />
                    <img src={ LogoText } alt="logo-text" className="ml-1 mt-1"/>
                </div>
                <Component />
            </div>
        </div>
    )
}

export default Page
