import { createContext, useContext } from "react"

const SnackbarsContext = createContext()


function useSnackbar() {
    const snackbarContext = useContext(SnackbarsContext)
    
    const setterFunc = (msg, seconds) => {
        const timeInSeconds = seconds || 10
        snackbarContext(msg, timeInSeconds)
    }
    
    return setterFunc 
}

export default useSnackbar
export { SnackbarsContext }