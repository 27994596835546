import React, { useEffect, useState } from 'react'
import { DataTable } from 'components'
import { usersActivitiesHead } from 'constants/tableConstants'
import Request from 'services/Request'
import useSnackbar from 'services/useSnackbar'
import { pathFor } from 'constants/apiPaths'
import { Dropdown } from 'react-bootstrap'
import { capitalise } from 'utils/common'


function UsersActivities(props) {    
    const { organisation_id, owned } = props
	const setMessage = useSnackbar()
	const [ userActivities, setUserActivities ] = useState([])
	const [ initiators, setInitiators ] = useState([])
	const [ filterBy, setFilterBy ] = useState(null)

    const handleFilter = (k, v) => {
        const temp = { ...filterBy }

        !!v.length ? temp[k] = v : delete temp[k]
        setFilterBy(temp)
    }

    useEffect(async () => {
		setUserActivities([])
		try {
            const { statusCode, jsonResponse } = await Request(pathFor.UsersActivities, { organisation_id, is_member: !owned })
            if (statusCode === 200) {
				const { data } = jsonResponse
				const activities = []
				data.forEach(task => {
					const userEmail = task.initiated_by.user_email
					delete task["initiated_by"]
					delete task["queued_on"]
					delete task.projection
					const status = task.status || "queued"
					const created_by = userEmail

					task = decodeTask(task)
					activities.push({task, status, created_by})
					initiators.includes(created_by) || setInitiators([created_by, ...initiators])
				})
                setUserActivities(activities)
            }
        } catch (err) {
            setMessage({error: "Server error. Try again after sometime."})
        }
	}, [ organisation_id, owned ])

    const decodeTask = task => {
		const { domain, __dollar__and, intent, lang, is_positive_emotion, keywords__dot__label } = task.query
		let taskObj = {}

		taskObj["taxonomies"] = __dollar__and?.filter(item => item.taxonomy__dot__label)?.[0]?.["taxonomy__dot__label"]?.["__dollar__in"]
		taskObj["keywords"] = keywords__dot__label?.__dollar__in
		taskObj["intents"] = intent?.__dollar__in
		taskObj["languages"] = lang?.__dollar__in
		taskObj["sentiments"] = is_positive_emotion ? ["Positive"] : is_positive_emotion === false ? ["Negative"] : ["Positive", "Negative"]
		taskObj["domains"] = domain?.__dollar__in

		return JSON.stringify({ ...(Object.fromEntries(Object.entries(taskObj).filter(([_, v]) => v?.length))) })
	}


    return (
        <div className="shadow rounded-widget flex-grow-1 cm-2 mb-4 d-flex flex-column">
            <div className="bg--primary text-white d-flex justify-content-between align-items-center rounded-header pl-4">
                <h6 className="font-weight-bold py-3 m-0">USERS ACTIVITIES</h6>
            </div>
            <div className="bg-white p-4 rounded-body flex-grow-1 h-100">
                <DataTable tableData={{ tableHead: usersActivitiesHead.filter(i => owned || i.hiddenFromMembers === owned), tableBody: userActivities }} filterBy={ filterBy } noExport is_custom >
                    <div className="d-flex">
                        <Dropdown onSelect={val => handleFilter("status", val)}>
                            <Dropdown.Toggle variant="white" id="dropdown-basic" className="bg-white border--light dropdown-selector d-flex align-items-center justify-content-between py-2">
                                { filterBy?.status ? capitalise(filterBy.status) : "Status" }
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    ["success", "queued", "failed"].map(item => (
                                        <Dropdown.Item eventKey={ item }>{ capitalise(item) }</Dropdown.Item>
                                        ))
                                    }
                            </Dropdown.Menu>
                        </Dropdown>
                        {
                            owned ? (
                                <Dropdown onSelect={val => handleFilter("created_by", val)}>
                                    <Dropdown.Toggle variant="white" id="dropdown-basic" className="bg-white border--light dropdown-selector ml-3 d-flex align-items-center justify-content-between py-2">
                                        { filterBy?.created_by || "Created by" }
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {
                                            initiators.map(item => (
                                                <Dropdown.Item eventKey={ item }>{ item }</Dropdown.Item>
                                                ))
                                            }
                                    </Dropdown.Menu>
                                </Dropdown>
                            ) : <></>
                        }
                    </div>
                </DataTable>
            </div>
        </div>
    )
}

export default UsersActivities